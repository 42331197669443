import { XGPT_API_URL } from "../../constants/config";
import { v4 as uuidv4 } from 'uuid';

const parseJSONResponse = async (response) => {
    // Check if the response is okay and content type is JSON
    if (response.ok && response.headers.get("content-type")?.includes("application/json")) {
        // Parse the JSON response
        return await response.json();
    } else {
        // Handle non-JSON responses or responses that are not okay
        return null;
    }
};


const handleShillQueries = async (argsFromAI, argsFromState) => {
    
    let messagesClone = argsFromState.messagesClone;
    let setMessages = argsFromState.setMessages;
    let setFetching = argsFromState.setFetching;
    let partnerInfo = argsFromState.partnerInfo;

    const tweetsJSON = partnerInfo.tweets;
    const tweets = JSON.stringify(tweetsJSON);

    const priceMovementJSON = await fetch(XGPT_API_URL + 'marketdatav2/getMarketData?tokenName=' + partnerInfo.name);
    const priceMovementResponse = await parseJSONResponse(priceMovementJSON);
    const priceMovement = JSON.stringify(priceMovementResponse);

    const roadmapJSON = partnerInfo.roadmap;
    const roadmap = JSON.stringify(roadmapJSON);


    messagesClone.push(
            { role: 'system', content: "The user asked for shill tweet. The user wants to know the latest tweets, price movements and upcoming roadmap of the token"},
            { role: 'system', content: "The token name is " + partnerInfo.name},
            { role: 'system', content: "Latest tweets are " + tweets },
            { role: 'system', content: "Latest price movements are " + priceMovement },
            { role: 'system', content: "Upcoming roadmap is " + roadmap },
            { role: 'system', content: "Use the above content of tweets, price movement and roadmap summarize them and prepare a shill tweet for the user to use. Write in crypto tone with emojis and make it catchy. Use the data from the above system messages to create the shill tweet. Make sure to include the token name in the tweet." },
        );

    //console.log(apiResponse, filterMessagesForOpenAI(messagesClone))
    await converseWithOpenAI();
    async function converseWithOpenAI() {
        try {

            //console.log(filterMessagesForOpenAI(messagesClone))

            const responseJSON = await fetch(XGPT_API_URL + 'openai/getAIFunctionResponse', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    mode: 'none',
                    messages: filterMessagesForOpenAI(messagesClone)
                })
            });
            //console.log("check 1", responseJSON)
            const openAIResponse = await responseJSON.json();
            setMessages(prevMessages => [...prevMessages, {role: 'assistant', content: openAIResponse.content, formattedContent: openAIResponse.content}]);
            setFetching(false);
        } catch (error) {
            console.error('Failed to fetch from OpenAI:', error);
            setFetching(false);
        }
    }

    function filterMessagesForOpenAI (rawMessages) {
        // Format the messages array for OpenAI API
        return rawMessages.map(message => {
            const { formattedContent, loadingMessageItem, id, isTyping,  ...rest } = message;
            return rest;
        });
    }
};

export { handleShillQueries };