import { useState, useEffect, createContext, propsWithChildern, useContext, useCallback } from 'react';
import detectEthereumProvider from '@metamask/detect-provider';

// Create a context for the current provider, and export it.
const MetaMaskContext = createContext({});

// Provider component that wraps your app and provides metamask state to your app and child components.
function MetaMaskContextProvider({ children }) {

    // Crate state for wallet, connection status, and error message.
    const [hasProvider, setHasProvider] = useState(null);
    const [isConnecting, setIsConnecting] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [wallet, setWallet] = useState({ accounts: [], balance: '', chainId: '' });

    // Helper to clear error message.
    const clearError = () => setErrorMessage('');

    // Helper to fetch and update wallet state 
    const updateWallet = useCallback(async (providedAccounts) => {
        const accounts = providedAccounts || await window.ethereum.request({ method: 'eth_accounts' });

        // If there are no accounts, then the user is disconnected.
        if(accounts.length === 0) {
            setWallet({ accounts: [], balance: '', chainId: '' });
            return;
        }

        // Fetch balance for the account
        const balance = await window.ethereum.request({
            method: 'eth_getBalance',
            params: [accounts[0], 'latest']
        });

        // Fetch chainId for the account
        const chainId = await window.ethereum.request({ method: 'eth_chainId' });
        
        // Update wallet state
        setWallet({ accounts, balance, chainId });
    }, []);

    // Detect the Ethereum provider (MetaMask) on page load
    useEffect(() => {
        const getProvider = async () => {
            const provider = await detectEthereumProvider({ silent: true });
            setHasProvider(Boolean(provider));

            // If provider is detected, set up event handlers for account and chain changes
            if (provider) {
                updateWallet();
                window.ethereum.on('accountsChanged', updateWallet);
                window.ethereum.on('chainChanged', updateWallet);
            }
        };

        getProvider();

        // Clean up event handlers on unmount
        return () => {
            window.ethereum?.removeListener('accountsChanged', updateWallet);
            window.ethereum?.removeListener('chainChanged', updateWallet);
        };
    }, [updateWallet]);

    // Helper to initiate connection to MetaMask
    const connectMetaMask = async () => {
        setIsConnecting(true);
        
        try {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            clearError();
            updateWallet(accounts);
        }
        catch (err) {
            setErrorMessage(err.message);
        }

        setIsConnecting(false);
    };

    // Provide the MetaMask state to child components
    return(
        <MetaMaskContext.Provider
            value={{
                wallet,
                hasProvider,
                error: !!errorMessage,
                errorMessage,
                isConnecting,
                connectMetaMask,
                clearError
            }}
        >
            {children}
        </MetaMaskContext.Provider>
    )
}

// Custom hook to consume the MetaMask context
function useMetaMask() {
    const context = useContext(MetaMaskContext);
    if (context === undefined) {
        throw new Error('useMetaMask must be used within a MetaMaskContextProvider');
    }
    return context;
}

// Export the providers and the hook for use in other components
export { MetaMaskContextProvider, useMetaMask }
export default MetaMaskContextProvider;