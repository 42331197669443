// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDDlwjgDNeh-txx7d97klJIbdyX1RixXNk",
    authDomain: "xgpt-partners.firebaseapp.com",
    projectId: "xgpt-partners",
    storageBucket: "xgpt-partners.appspot.com",
    messagingSenderId: "360770851713",
    appId: "1:360770851713:web:393488e5977e68f0ab4e80",
    measurementId: "G-459WXCW2PH"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { db };
