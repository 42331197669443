import { XGPT_API_URL } from "../../constants/config";
import { v4 as uuidv4 } from 'uuid';

const handleNewsQueries = async (argsFromAI, argsFromState) => {
    
    let messagesClone = argsFromState.messagesClone;
    let setMessages = argsFromState.setMessages;
    let setFetching = argsFromState.setFetching;
    let newsCategory = argsFromAI.newsCategory;
    
    let apiResponseJSON = "";
    let trendingHeadlinesFormat = [];
    let regulationNewsFormat = [];
    let dailyDigestFormat = [];
    let nftNewsFormat = [];

    if(newsCategory === "latestTrendingNews") {
        apiResponseJSON = await fetch(XGPT_API_URL + 'news/getTrendingHeadlines');
        trendingHeadlinesFormat = [
            { role: 'system', content: "Display headline, text, sentiment, and any tickers if mentioned. Display headline in bold text. Display text in regular text. Display the tickers with a `$` sign in the front. Do not use labels at all." },
            {role: 'system', content: "Prefix thumbsup or thumbsdown emojis for sentiment. DO not display any other information. Do not use emoji other than the sentiment"}
        ]
    } else if (newsCategory === "regulationNews") {
        apiResponseJSON = await fetch(XGPT_API_URL + 'news/getRegulationNews');
        regulationNewsFormat = [
            { role: 'system', content: "Display headline, text, sentiment, and any tickers if mentioned. Display headline in bold text. Display text in regular text. Display the tickers with a `$` sign in the front. Do not use labels at all." },
            { role: 'system', content: "Provide a read more link if news_url field is available." },
            { role: 'system', content: "Prefix thumbsup or thumbsdown emojis for sentiment. DO not display any other information. Do not use emoji other than the sentiment" }
        ]
    } else if (newsCategory === "dailyDigest") {
        apiResponseJSON = await fetch(XGPT_API_URL + 'news/getDailyDigest');
        dailyDigestFormat = [
            { role: 'system', content: "Read through the daily digest system message and explain it to the user, in the most friendliest and engaging way" }
        ]
    } else if (newsCategory === "nftNews") {
        apiResponseJSON = await fetch(XGPT_API_URL + 'news/getNFTNews');
        nftNewsFormat = [
            { role: 'system', content: "Display title, text, sentiment, date, news_url, and source_name if mentioned. Display title in bold text. Display text in regular text. Do not use labels at all." },
            { role: 'system', content: "Provide a read more link if news_url field is available." },
            { role: 'system', content: "Prefix thumbsup or thumbsdown emojis for sentiment. DO not display any other information. Do not use emoji other than the sentiment" }
        ]
    } 
    else {
        apiResponseJSON = await fetch(XGPT_API_URL + 'news/getTrendingHeadlines');
    }


    const apiResponse = await apiResponseJSON.json();

    // conver the entire array into a string
    const apiResponseString = JSON.stringify(apiResponse);

    
    messagesClone.push(
            { role: 'system', content: apiResponseString },
            { role: 'system', content: "The above system message has the latest top 10 trending news headlines. Use the info to display them in markdown style in the most readable format." },
            { role: 'system', content: "Use enough spacing between each news item. Try to make each item look like a card. All font size should be 16px. Do not show any images" },
        );
    
    if (newsCategory === "latestTrendingNews") {
        messagesClone.push(...trendingHeadlinesFormat);
    } else if (newsCategory === "regulationNews") {
        messagesClone.push(...regulationNewsFormat);
    } else if (newsCategory === "dailyDigest") {
        messagesClone.push(...dailyDigestFormat);
    } else if (newsCategory === "nftNews") {
        messagesClone.push(...nftNewsFormat);
    }

    //console.log(apiResponse, filterMessagesForOpenAI(messagesClone))
    await converseWithOpenAI();
    async function converseWithOpenAI() {
        try {

            //console.log(filterMessagesForOpenAI(messagesClone))

            const responseJSON = await fetch(XGPT_API_URL + 'openai/getAIResponse', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    mode: 'none',
                    messages: filterMessagesForOpenAI(messagesClone)
                })
            });

            const openAIResponse = await responseJSON.json();
            setMessages(prevMessages => [...prevMessages, {role: 'assistant', content: openAIResponse.content, formattedContent: openAIResponse.content}]);
            setFetching(false);
        } catch (error) {
            console.error('Failed to fetch from OpenAI:', error);
            setFetching(false);
        }
    }

    function filterMessagesForOpenAI (rawMessages) {
        // Format the messages array for OpenAI API
        return rawMessages.map(message => {
            const { formattedContent, loadingMessageItem, id, isTyping,  ...rest } = message;
            return rest;
        });
    }
};

export { handleNewsQueries };