import { useState, createContext, useContext } from 'react';


// Create a context for the current provider, and export it.
const ThemeContext = createContext({});


// Provider component that wraps your app and provides the chat state variables to your app and child components.
function ThemeContextProvider({ children }) {
    const [theme, setTheme] = useState('dark');

    const handleTheme = () => {
        setTheme((current) => current === 'light' ? 'dark' : 'light');
    }
    return (
        <ThemeContext.Provider 
            value={{ 
                theme,
                handleTheme
            }}>
            {children}
        </ThemeContext.Provider>
    )
}

// Custom hook to consume the MetaMask context
function useTheme() {
    const context = useContext(ThemeContext);
    if (context === undefined) {
        throw new Error('');
    }
    return context;
}

export {ThemeContextProvider, useTheme}
export default ThemeContextProvider