import React, { useState, useEffect } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import styles from './chat.module.scss';
import { IoMdSend } from 'react-icons/io';
import { BsPersonCircle } from 'react-icons/bs';
import ReactPlayer from 'react-player';
import AIVideo from './../assets/videos/ai.mov'
import Logo from './../assets/images/logo.svg'
import useChatMethod from '../hooks/useChat';
import SiriWave from 'react-siriwave'
import { useMetaMask } from '../hooks/useMetaMask';
import { useChat } from '../hooks/useChat';
import { useTheme } from '../hooks/useTheme';
import Markdown from 'react-markdown'


// We are going to make this chat component dynamic.
// If there is partnerInfo in the chat context, we will have to fetch partner's info from Firebase and ue it everywhere.
// If there is no partnerInfo, we will show the default chat interface.

function XGPTIntro() {

    const { wallet, hasProvider, isConnecting, connectMetaMask } = useMetaMask();
    const { handlePromptInput } = useChat();
    const { theme } = useTheme();

    const prompts = [
        {
            title: "Twitter Trending",
            prompt: "Suggest me the trending tokens on Twitter.",
        },
        {
            title: "Latest Headlines",
            prompt: "Get me the latest trending news in crypto world.",
        },
        {
            title: "Catch up with Regulations",
            prompt: "What are the latest regulations in the crypto world?",
        },
        {
            title: "Daily Digest",
            prompt: "Give me a digest of what happened yesterday in the crypto world.",
        },
        {
            title: "Catch up on NFTs",
            prompt: "What's happening in the NFT world recently?",
        },
        {
            title: "Ask about Connected Wallet",
            prompt: "List all the tokens in  my wallet.",
        },
    ]


    return (
        <div className={styles.introContainer}>
            <div className={styles.playerOuterWrapper}>
                {/* <div className={styles.playerWrapper}>
                    <ReactPlayer
                        className={styles.reactPlayer}
                        url={AIVideo}
                        height="300px"
                        width="300px"
                        playing
                        muted
                        loop
                        playsinline
                    />
                </div> */}
                <a href="/" className={styles.logo}>
                    <img src={Logo} alt="Logo" className={styles.logoImg} />
                </a>
            </div>
            <div className={styles.introTitle}>Ask “X” with your prompt.</div>
            <div className={styles.introText}>AI Powered Conversational User Interface for Cryptocurrency.</div>
            <div className={styles.tilesContainer}>
                {prompts.map((prompt, index) => {
                    return (
                        <div className={styles.tile} key={index} onClick={() => handlePromptInput(prompt.prompt)}>
                            <div className={styles.tileTitle}>{prompt.title}</div>
                            <div className={styles.tileText}>{prompt.prompt}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

function PartnerIntro() {

    const { wallet, hasProvider, isConnecting, connectMetaMask } = useMetaMask();
    const { handlePartnerPromptInput, partnerInfo } = useChat();
    const { theme } = useTheme();

    const prompts = [
        {
            title: "Whitepaper",
            prompt: "Summarize me the whitepaper",
        },
        {
            title: "Roadmap",
            prompt: "What are the upcoming milestones?",
        },
        {
            title: "Price Movements",
            prompt: "Tell me about the recent movements in price",
        },
        {
            title: "Team",
            prompt: "Who are the founders and team members?",
        },
        {
            title: "Twitter",
            prompt: "Get the latest tweets from the project",
        },
        {
            title: "Shill",
            prompt: "Compose a shill tweet based on latest info",
        },
    ]

    const containerStyle = {
        '--primary-accent': partnerInfo ? partnerInfo.theme.dark.primaryAccent : "",
        '--secondary-accent': partnerInfo ? partnerInfo.theme.dark.secondaryAccent : "",
        '--primary-surface': partnerInfo ? partnerInfo.theme.dark.primarySurface : "",
        '--secondary-surface': partnerInfo ? partnerInfo.theme.dark.secondarySurface : "",
        '--tertiary-surface': partnerInfo ? partnerInfo.theme.dark.tertiarySurface : "",
        '--primary-foreground': partnerInfo ? partnerInfo.theme.dark.primaryForeground : "",
        '--secondary-foreground': partnerInfo ? partnerInfo.theme.dark.secondaryForeground : "",
      };
    

    return (
        <div className={styles.introContainer}>
            <div className={styles.playerOuterWrapper}>
                {/* <div className={styles.playerWrapper}>
                    <ReactPlayer
                        className={styles.reactPlayer}
                        url={AIVideo}
                        height="300px"
                        width="300px"
                        playing
                        muted
                        loop
                        playsinline
                    />
                </div> */}
                <a href="/" className={styles.logo}>
                    <img src={partnerInfo.logo[0].downloadURL} alt="Logo" height={96} width={96} className={styles.logoImg} />
                </a>
            </div>
            <div className={styles.introTitle}  style={containerStyle}>{partnerInfo ? partnerInfo.name : ""} AI Assistant</div>
            <div className={styles.introText}  style={containerStyle}>Powered by <a className={styles.link} href="https://www.xgpt-ai.com" target='_blank'>XGPT AI</a></div>
            <div className={styles.tilesContainer} style={containerStyle}>
                {prompts.map((prompt, index) => {
                    return (
                        <div className={styles.tile} style={containerStyle} key={index} onClick={() => handlePartnerPromptInput(prompt.prompt)}>
                            <div className={styles.tileTitle} style={containerStyle}>{prompt.title}</div>
                            <div className={styles.tileText} style={containerStyle}>{prompt.prompt}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

// Create a component called Intro.
// If partnerInfo is not null, Intro should be PartnerIntro.
// If partnerInfo is null, Intro should be XGPTIntro.
function Intro() {
    const { partnerInfo } = useChat();
    return partnerInfo ? <PartnerIntro /> : <XGPTIntro />
}


function Chat() {

    const { messages, input, handleInputChange, handleFormSubmit, handlePartnerFormSubmit, showIntro, fetching, partnerInfo} = useChat();
    const [animate, setAnimate] = useState(false);
    const { theme } = useTheme();

    const containerStyle = {
        '--primary-accent': partnerInfo && partnerInfo.theme ? partnerInfo.theme.dark.primaryAccent : "",
        '--secondary-accent': partnerInfo && partnerInfo.theme ? partnerInfo.theme.dark.secondaryAccent : "",
        '--primary-surface': partnerInfo && partnerInfo.theme ? partnerInfo.theme.dark.primarySurface : theme === 'light' ? '#d3e4f5' : '',
        '--secondary-surface': partnerInfo && partnerInfo.theme ? partnerInfo.theme.dark.secondarySurface : theme === 'light' ? 'white' : '',
        '--tertiary-surface': partnerInfo && partnerInfo.theme ? partnerInfo.theme.dark.tertiarySurface : theme === 'light' ? '#d3e4f5' : '',
        '--parimary-foreground': partnerInfo && partnerInfo.theme ? partnerInfo.theme.dark.parimaryForeground : "",
        '--secondary-foreground': partnerInfo && partnerInfo.theme ? partnerInfo.theme.dark.secondaryForeground : theme === 'light' ? '#333b42' : '',
        '--primary-foreground': partnerInfo &&  partnerInfo.theme ? partnerInfo.theme.dark.primaryForeground : theme === 'light' ? '#000000' : '',
      };

    
    
  
    // useEffect(() => {
        
    //     setAnimate(true);
    
    //     // You might want to set it back to false after some time if needed
    //     const timeout = setTimeout(() => {
    //       setAnimate(false);
    //     }, 5000); // Reset the animate state after 5 seconds
    
    //     // Clear the timeout when the component unmounts or messages change
    //     return () => clearTimeout(timeout);
    // }, [messages]);
    

    // UI Element: User's chat message pill
    const UserMessage = (message, index) => {
        return (
            <div className={`${styles.messageItemContainer} ${styles.user}`} style={containerStyle} key={index}>
                <div className={styles.avatar}><BsPersonCircle /></div>
                <div className={styles.messageContent}>
                    <div className={styles.messageType}>{message.role == "user" ? "You" : "XGPT"}</div>
                    <div className={styles.messageText} style={{ color: partnerInfo ? partnerInfo.theme.dark.primaryForeground : "" }}>{message.content}</div>
                </div>
            </div>
        )
    };

    // UI Element: AI's chat message pill
    const AIMessage = (message, index) => {
        if(partnerInfo) {
            return (
                <div className={`${styles.messageItemContainer} ${styles.ai}`} key={index}>
                    <div className={styles.avatar}>
                        <img src={partnerInfo.logo[0].downloadURL} alt='avatar' height={24} width="auto" />
                    </div>
                    <div className={styles.messageContent}>
                        <div className={styles.messageType} style={{ color: partnerInfo ? partnerInfo.theme.dark.secondaryForeground : "" }}>{message.role == "user" ? "You" : partnerInfo.name}</div>
                        <div className={styles.messageText} style={{ color: partnerInfo ? partnerInfo.theme.dark.primaryForeground : "" }}>
                            <Markdown>{message.content}</Markdown>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={`${styles.messageItemContainer} ${styles.ai}`} key={index}>
                    <div className={styles.avatar}>
                        <img src={Logo} alt='avatar' height={24} width="auto" />
                    </div>
                    <div className={styles.messageContent}>
                        <div className={styles.messageType}>{message.role == "user" ? "You" : "XGPT"}</div>
                        <div className={styles.messageText}>
                            <Markdown>{message.content}</Markdown>
                        </div>
                    </div>
                </div>
            )
        }
    };

    // UI Element: AI's thinking message
    const AIThinkingMessage = () => {
        return (
            <div className={`${styles.messageItemContainer} ${styles.ai} ${styles.thinking}`} style={containerStyle}>
                <div className={styles.messageContent}>
                    <SiriWave pixelDepth={0.01} className={styles.thinkingWave} speed={0.2} color={partnerInfo ? partnerInfo.theme.dark.secondaryAccent : '#B8E6BB'} amplitude={0.5} frequency={100} cover={true}  />
                </div>
            </div>
        )
    };

    // UI Element: Messages box
    const MessagesBox = 
        messages.map((message, index) => {
                if(message.role !== "system") {
                    return message.role == "user" ? UserMessage(message, index) : AIMessage(message, index)
                }
                else {
                    return null;
                }
        });

    MessagesBox.push(fetching ? AIThinkingMessage() : null);

    const partnerPrimaryAccent = partnerInfo && partnerInfo.theme ? partnerInfo.theme.dark.primaryAccent : "#F243AC";
    const partnerSecondaryAccent = partnerInfo && partnerInfo.theme ? partnerInfo.theme.dark.secondaryAccent : "#F243AC";

    

    return (
        <div className={styles.chat}>
            <Container className={styles.chatContainer}>
                <div className={`${styles.messagesOuterContainer} ${animate ? styles.animate : ""}`} style={{ background: `linear-gradient(90deg, ${partnerPrimaryAccent} 50%, ${partnerSecondaryAccent} 100%)` }}>
                    <div id="chatWindow" className={styles.messagesContainer} style={containerStyle}>
                        {showIntro ? <Intro /> : MessagesBox}
                    </div>
                </div>
                <div className={styles.inputOuterContainer} style={containerStyle}>
                    <Form onSubmit={partnerInfo ? handlePartnerFormSubmit : handleFormSubmit} className={styles.inputContainer} style={containerStyle}>
                        <Form.Control
                            className={styles.input}
                            style={{ color: partnerInfo ? partnerInfo.theme.dark.primaryForeground : theme === 'light' ? '#000000' : "#ffffff" }}
                            type="text"
                            placeholder="Type a message..."
                            value={input}
                            onChange={handleInputChange} />
                        <Button
                            className={styles.customButton}
                            variant="primary"
                            type="submit">
                                <IoMdSend className={styles.iconText} />
                        </Button>
                    </Form>
                </div>
            </Container>
        </div>
    )
}

export default Chat;