import { XGPT_API_URL } from "../../constants/config";
import { v4 as uuidv4 } from 'uuid';

const parseJSONResponse = async (response) => {
    // Check if the response is okay and content type is JSON
    if (response.ok && response.headers.get("content-type")?.includes("application/json")) {
        // Parse the JSON response
        return await response.json();
    } else {
        // Handle non-JSON responses or responses that are not okay
        return null;
    }
};


const handleTweetsQueries = async (argsFromAI, argsFromState) => {
    
    let messagesClone = argsFromState.messagesClone;
    let setMessages = argsFromState.setMessages;
    let setFetching = argsFromState.setFetching;
    let partnerInfo = argsFromState.partnerInfo;

    console.log("partnerInfo", partnerInfo)

    const apiResponseJSON = partnerInfo.tweets;
    const apiResponse = await JSON.stringify(apiResponseJSON);

    messagesClone.push(
            { role: 'system', content: apiResponse },
            { role: 'system', content: "The above system message has the recent tweets. Present this to the user with markdown styling. Use the links to provide a footer for each tweet. Use appropriate emoji based on the context. Make sure the link opens in new tab."},
        );

    //console.log(apiResponse, filterMessagesForOpenAI(messagesClone))
    await converseWithOpenAI();
    async function converseWithOpenAI() {
        try {

            //console.log(filterMessagesForOpenAI(messagesClone))

            const responseJSON = await fetch(XGPT_API_URL + 'openai/getAIFunctionResponse', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    mode: 'none',
                    messages: filterMessagesForOpenAI(messagesClone)
                })
            });
            //console.log("check 1", responseJSON)
            const openAIResponse = await responseJSON.json();
            setMessages(prevMessages => [...prevMessages, {role: 'assistant', content: openAIResponse.content, formattedContent: openAIResponse.content}]);
            setFetching(false);
        } catch (error) {
            console.error('Failed to fetch from OpenAI:', error);
            setFetching(false);
        }
    }

    function filterMessagesForOpenAI (rawMessages) {
        // Format the messages array for OpenAI API
        return rawMessages.map(message => {
            const { formattedContent, loadingMessageItem, id, isTyping,  ...rest } = message;
            return rest;
        });
    }
};

export { handleTweetsQueries };