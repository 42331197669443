import { ethers } from "ethers";
import { CoinsList } from "../constants/coinslist";

function getMatchingCoin(userInput) {
    // Remove punctuation and lowercase the user input, then split it into words
    const words = userInput.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()?]/g, "").toLowerCase().split(' ');

    let matches = [];

    // Loop through each word in the user input
    for (const word of words) {
        // Find the coin that matches the word
        const matchingCoin = CoinsList.find(coin =>
            coin.id.toLowerCase() === word || coin.symbol.toLowerCase() === word || coin.name.toLowerCase() === word
        );        

        // If a matching coin is found, push it to matches array
        if (matchingCoin) {
            matches.push(matchingCoin);
        }
    }

    // If matches array is not empty, sort it by the length of coin's name in descending order
    if (matches.length > 0) {
        matches.sort((a, b) => b.name.length - a.name.length);
        return matches[0];  // return the first element of sorted array
    } else {
        return null;
    }
}

// Function to get matching token pair with the api call https://api.dexscreener.com/latest/dex/search/?q=tokenName
// In the response response.pairs has an array of all matching token name in all chains. Filter the one with chainId = "ethereum"
async function getTokenPairDetails(tokenName) {
    const response = await fetch(`https://api.dexscreener.com/latest/dex/search/?q=${tokenName}`);
    const data = await response.json();
    const tokenPair = data.pairs.find(pair => pair.chainId === "ethereum");
    //console.log(tokenPair)
    return tokenPair;
}

async function getCoinData(tokenAddress) {
  const response = await fetch(`https://us-central1-xgpt-server.cloudfunctions.net/api/token?chain=ether&address=${tokenAddress}&apiKey=eb1559fd8b73378d5ca903fe0b3867b9`)

  // Check if the request was successful
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  } else {
    const coinData = await response.json();
    //console.log(coinData.data)
    return coinData.data;
  }
}

async function getPairData(pairAddress) {
  const response = await fetch(`https://us-central1-xgpt-server.cloudfunctions.net/api/pair?chain=ether&address=${pairAddress}&apiKey=eb1559fd8b73378d5ca903fe0b3867b9`)

  // Check if the request was successful
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  } else {
    const pairData = await response.json();
    return pairData.data;
  }
}

// get coinData and format the result from the same JSON response below
// Get pair info and populate the data from the sample JSON response below
// {"creationBlock":17800278,"dextScore":{"total":66},"metrics":{"liquidity":20030.673979701485,"reserve":35311.79926499559,"reserveRef":6.016713093743263},"token":{"name":"XGPT","symbol":"XGPT","address":"0x51fe05eac152494908ff1ebbd50e116e960baf64"},"tokenRef":{"name":"Wrapped Ether","symbol":"WETH","address":"0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"},"price":0.29158561863984905,"price24h":{"volume":697.3172773633639,"swaps":6,"price":0.3083558872478874,"priceChain":0.00018369531886555456,"buys":3,"sells":3},"chain":"ether","exchange":"univ2","address":"0x2e95bd33c562ece0335e7478ee0437a249a89cb7"}

async function getMarketData(tokenAddress) {
    const tokenInfo = await getCoinData(tokenAddress);
    const pairInfo = await getPairData(tokenInfo.reprPair.id.pair)
    const formattedResult = {
      "Name": tokenInfo.name,
      "Symbol": tokenInfo.symbol,
      "Price": tokenInfo.reprPair.price,
      "Market Cap": tokenInfo.reprPair.price * tokenInfo.metrics.totalSupply,
      "Address": tokenInfo.address,
      "Chain": tokenInfo.chain,
      "Total Supply": tokenInfo.metrics.totalSupply,
      "Max Supply": tokenInfo.metrics.maxSupply,
      "Total Holders": tokenInfo.metrics.holders,
      "Total Transactions": tokenInfo.metrics.txCount,
      "Is Contract Renounced": tokenInfo.audit.is_contract_renounced,
      "Code Verified": tokenInfo.audit.codeVerified,
      "Decimals": tokenInfo.decimals,
      "Description": tokenInfo.info.description,
      "Email": tokenInfo.info.email,
      "LinkedIn Page": tokenInfo.info.extraInfo,
      "Social Media Links": {
        "Facebook": tokenInfo.links.facebook,
        "Instagram": tokenInfo.links.instagram,
        "Telegram": tokenInfo.links.telegram,
        "TikTok": tokenInfo.links.tiktok,
        "Twitter": tokenInfo.links.twitter,
        "Website": tokenInfo.links.website,
        "Youtube": tokenInfo.links.youtube
      },
      "Pair Info": {
        "Creation Block": pairInfo.creationBlock,
        "DEX Score": pairInfo.dextScore.total,
        "Exchange": pairInfo.exchange,
        "Liquidity": pairInfo.metrics.liquidity,
        "Token 1 Reserve": pairInfo.metrics.reserve + pairInfo.token.symbol,
        "Token 2 Reserve": pairInfo.metrics.reserveRef + pairInfo.tokenRef.symbol,
        "Token 1 Info": pairInfo.token,
        "Token 2 Info": pairInfo.tokenRef,
        "Price": pairInfo.price,
        "24 hour trade data": {
          "Volume": pairInfo.price24h.volume,
          "Buys": pairInfo.price24h.buys,
          "Sells": pairInfo.price24h.sells,
        }
      }
    }
    const formattedResultString = JSON.stringify(formattedResult, null, 2);
    return formattedResultString;
  }
  

export const MarketdataService = {
  getMarketData,
  getMatchingCoin,
  getCoinData,
  getTokenPairDetails
};
