import { XGPT_API_URL } from "../../constants/config";
import { v4 as uuidv4 } from 'uuid';

const parseJSONResponse = async (response) => {
    // Check if the response is okay and content type is JSON
    if (response.ok && response.headers.get("content-type")?.includes("application/json")) {
        // Parse the JSON response
        return await response.json();
    } else {
        // Handle non-JSON responses or responses that are not okay
        return null;
    }
};

const handleMarketDataQueries = async (argsFromAI, argsFromState) => {

    let messagesClone = argsFromState.messagesClone;
    let setMessages = argsFromState.setMessages;
    let setFetching = argsFromState.setFetching;
    let responseJSON;
    let response;

    
    // GET matchingCoin info from XGPT API
    // If token is on ethereum platform and has a contract address then fetch the token pair details from XGPT API
    // If token is not on ethereum platform then fetch the market data from CMC API
    // If matchingCoin is null or undefined then fetch market data from XGPT API
    
    // Validate info
    let infoValidated = await validateInfoQuery();

    if(!infoValidated) {
     return;   
    } else {
        responseJSON = await fetch(XGPT_API_URL + 'marketdatav2/getMarketData?tokenName=' + argsFromAI.tokenName);
        console.log("responseJSON", responseJSON)
        response = responseJSON == null ? "The mentioned coin is not found. Please ask the user to try again with the right coin or token name" : await parseJSONResponse(responseJSON);


        messagesClone.push(
            { role: 'system', content: JSON.stringify(response) },
            { role: 'system', content: "The above system message has all information about the token the user asked for. Pick the info which the user asked for and reply" },
            {
                role: 'system',
                content: 'Always start with the logo. If there is a logo url available in the system messages, with the help of markdown display the logo to the user. If its not available then do not display the logo.'
            }
        );

        await converseWithOpenAI();
        async function converseWithOpenAI() {
            //console.log("check 1", filterMessagesForOpenAI(messagesClone))
            try {
                const responseJSON = await fetch(XGPT_API_URL + 'openai/getAIFunctionResponse', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        mode: 'none',
                        messages: filterMessagesForOpenAI(messagesClone)
                    })
                });
                //console.log("check 1", responseJSON)
                const openAIResponse = await responseJSON.json();

                // This was take from expo mobile. For Web we are have a different approach temporarily
                // const loadingMessageIndex = messagesClone.findIndex(message => message.loadingMessageItem);
                // messagesClone[loadingMessageIndex] = { role: 'assistant', content: openAIResponse.content, formattedContent: openAIResponse.content, id: uuidv4(), isTyping: true};
                // setMessages(messagesClone);

                // Approach for web
                setMessages(prevMessages => [...prevMessages, {role: 'assistant', content: openAIResponse.content, formattedContent: openAIResponse.content}]);
                setFetching(false);
            } catch (error) {
                console.error('Failed to fetch from OpenAI:', error);
                setFetching(false);
            }
        }

        function filterMessagesForOpenAI (rawMessages) {
            // Format the messages array for OpenAI API
            return rawMessages.map(message => {
                const { formattedContent, loadingMessageItem, id, isTyping,  ...rest } = message;
                return rest;
            });
        }
    }

    async function validateInfoQuery() {
        if(argsFromAI.tokenName == null || argsFromAI.tokenName == undefined || argsFromAI.tokenName == "" ) {
            messagesClone.push(
                { role: 'system', content: "Please provide the token name or symbol. Whatever the user says next will most likely the token name or symbol." }
            );
            setMessages(messagesClone);
            return false;
        }
        return true;
    }
};

export { handleMarketDataQueries };