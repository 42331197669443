import { XGPT_API_URL } from "../../constants/config";
import { v4 as uuidv4 } from 'uuid';

const handleWalletInfoQueries = async (argsFromAI, argsFromState) => {

    //console.log("Handle wallet info queries", connectedWalletInfo, walletAddress)

    let messagesClone = argsFromState.messagesClone;
    let setMessages = argsFromState.setMessages;
    let setFetching = argsFromState.setFetching;
    let connectedWalletInfo = argsFromState.connectedWalletInfo;
    let wallet = argsFromState.wallet;
    let responseJSON;
    let response;

    //console.log("working", wallet, connectedWalletInfo)

    responseJSON = await fetch(XGPT_API_URL + 'blockexplorer/getAPIResponse?input=' + wallet.accounts[0]);
    response = await responseJSON.json();

    

    // Construct connected wallet info as system message    
    const walletInfoContent = 
    connectedWalletInfo == "NotConnected" ? "User has not connected his wallet. Connect wallet option available in the side menu" :
    connectedWalletInfo == "Connected" ? "User wallet has been connected. Here are the details about the connected wallet: " + JSON.stringify(response) : 
    null;

    // If connected wallet info is not null, add it to the messagesClone array
    if (walletInfoContent) {
        messagesClone.push(
            {
                role: 'system',
                content: walletInfoContent,
            },
            { role: 'system', content: "The above system message has all information about the token the user asked for. Pick the info which the user asked for and reply. Only answer to the user's question. If you could find more related info, ask the user if they would like to know about those" },
            {
                role: 'system',
                content: 'Use markdown to display the information in a readable format.'
            }
        );
    }

    await converseWithOpenAI();
    async function converseWithOpenAI() {
        try {

            //console.log(filterMessagesForOpenAI(messagesClone))

            const responseJSON = await fetch(XGPT_API_URL + 'openai/getAIFunctionResponse', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    mode: 'none',
                    messages: filterMessagesForOpenAI(messagesClone)
                })
            });
            //console.log("check 1", responseJSON)
            const openAIResponse = await responseJSON.json();
            //console.log("check 2", openAIResponse);
        
            // Assuming the response has a 'content' field and you need to parse it
            // const loadingMessageIndex = messagesClone.findIndex(message => message.loadingMessageItem);
            // messagesClone[loadingMessageIndex] = { role: 'assistant', content: openAIResponse.content, formattedContent: openAIResponse.content, id: uuidv4(), isTyping: true};
            // setMessages(messagesClone);

            // Approach for web
            setMessages(prevMessages => [...prevMessages, {role: 'assistant', content: openAIResponse.content, formattedContent: openAIResponse.content}]);
            setFetching(false);
        } catch (error) {
            console.error('Failed to fetch from OpenAI:', error);
            setFetching(false);
        }
    }

    function filterMessagesForOpenAI (rawMessages) {
        // Format the messages array for OpenAI API
        return rawMessages.map(message => {
            const { formattedContent, loadingMessageItem, id, isTyping,  ...rest } = message;
            return rest;
        });
    }

    async function getBlockexplorerAPIResponse (input) {
        // Get the API response from the blockexplorer service
        const responseJSON = await fetch(XGPT_API_URL + 'blockexplorer/getAPIResponse?input=' + input);
        const response = await responseJSON.json();
        return JSON.stringify(response);
    }
};

export { handleWalletInfoQueries };