import { XGPT_API_URL } from "../../constants/config";
import { v4 as uuidv4 } from 'uuid';

const handleBlockExplorerQueries = async (argsFromAI, argsFromState) => {
    
    let messagesClone = argsFromState.messagesClone;
    let setMessages = argsFromState.setMessages;
    let setFetching = argsFromState.setFetching;

    // Validate info
    let infoValidated = await validateInfoQuery();

    if(!infoValidated) {
        return;
    }

    const detailsJSON = await fetch(XGPT_API_URL + 'blockexplorer/extractDetailsFromInput?input=' + argsFromAI.hexadecimalValue);
    const details = await detailsJSON.json(); 

    const apiResponse = details.transactionHash || details.addressType ? await getBlockexplorerAPIResponse(argsFromAI.hexadecimalValue) : "I'm sorry, the value you entered is not a valid transaction hash or address. Please try again.";

    messagesClone.push(
            { role: 'system', content: apiResponse },
        );

    //console.log(apiResponse, filterMessagesForOpenAI(messagesClone))
    await converseWithOpenAI();
    async function converseWithOpenAI() {
        try {

            //console.log(filterMessagesForOpenAI(messagesClone))

            const responseJSON = await fetch(XGPT_API_URL + 'openai/getAIFunctionResponse', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    mode: 'none',
                    messages: filterMessagesForOpenAI(messagesClone)
                })
            });
            //console.log("check 1", responseJSON)
            const aiResponse = await responseJSON.json();
            //console.log("check 2", openAIResponse);
        
            // Assuming the response has a 'content' field and you need to parse it
            // const openAIResponseFormatted = await fetch(XGPT_API_URL + 'blockexplorer/parseResponse?response=' + openAIResponse.content);
            // const loadingMessageIndex = messagesClone.findIndex(message => message.loadingMessageItem);
            // messagesClone[loadingMessageIndex] = { role: 'assistant', content: openAIResponse.content, formattedContent: openAIResponseFormatted, id: uuidv4(), isTyping: true};
            // setMessages(messagesClone);
            setMessages(prevMessages => [...prevMessages, {role: 'assistant', content: aiResponse.content, formattedContent: aiResponse.content}]);
            setFetching(false);
        } catch (error) {
            console.error('Failed to fetch from OpenAI:', error);
            setFetching(false);
        }
    }

    async function validateInfoQuery() {
        if(argsFromAI.hexadecimalValue == null || argsFromAI.hexadecimalValue == undefined || argsFromAI.hexadecimalValue == "" ) {
            messagesClone.push(
                { role: 'system', content: "Please ask the user to provide a wallet address, contract address or transaction hash. Get the user's input from the next message" }
            );
            setMessages(messagesClone);
            return false;
        }
        return true;
    }

    function filterMessagesForOpenAI (rawMessages) {
        // Format the messages array for OpenAI API
        return rawMessages.map(message => {
            const { formattedContent, loadingMessageItem, id, isTyping,  ...rest } = message;
            return rest;
        });
    }

    async function getBlockexplorerAPIResponse (input) {
        // Get the API response from the blockexplorer service
        const responseJSON = await fetch(XGPT_API_URL + 'blockexplorer/getAPIResponse?input=' + argsFromAI.hexadecimalValue);
        const response = await responseJSON.json();
        // convert the response to a string and return
        return JSON.stringify(response);
    }
};

export { handleBlockExplorerQueries };