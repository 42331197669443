import { ethers } from "ethers";
import { Network, Alchemy } from "alchemy-sdk";
import { EtherscanService } from "./etherscan.service";

import { ChainId, Token, WETH, Pair, TokenAmount, Fetcher } from '@uniswap/sdk'

// Optional config object, but defaults to demo api-key and eth-mainnet.
const settings = {
  apiKey: process.env.REACT_APP_ALCHEMY_API_KEY, // Replace with your Alchemy API Key.
  network: Network.ETH_MAINNET, // Replace with your network.
};
const alchemy = new Alchemy(settings);

// Replace with your Alchemy API key:
const apiKey = process.env.REACT_APP_ALCHEMY_API_KEY;
const fetchURL = `https://eth-mainnet.g.alchemy.com/v2/${apiKey}`;
const provider = new ethers.providers.JsonRpcProvider(fetchURL);

// Function to return uniswap weth pair for a given token address
async function getUniswapPair(tokenAddress) {
  const token = new Token(ChainId.MAINNET, tokenAddress, 18);
  const pairData = await Fetcher.fetchPairData(token, WETH[token.chainId], provider);
  //console.log('Pair address: ', pairData);
  return pairData;
}

/** Utility functions */

/**
 * getTradeDetails function begins here
 * Returns details of the trade tradeType, tradePriceInETH, tokensAmount, tradeSlippageTolerance
*/
async function getTradeDetails(uniswapPair, tradeType, tradeAmountInETH) {
  //console.log("uniswapPair: ", uniswapPair, "tradeType: ", tradeType, "tradeAmountInETH: ", tradeAmountInETH)
  //const token0 = new Token(ChainId.MAINNET, uniswapPair.token0.id, uniswapPair.token0.symbol, uniswapPair.token0.symbol, 18);
  //const token1 = new Token(ChainId.MAINNET, uniswapPair.token1.id, uniswapPair.token1.symbol, uniswapPair.token1.symbol, 18);
  //const pair = new Pair(new TokenAmount(token0, uniswapPair.reserve0), new TokenAmount(token1, uniswapPair.reserve1));
  //const route = new Route([pair], token0);
  //const trade = new Trade(route, new TokenAmount(token0, tradeAmountInETH), tradeType);
  const tradeDetails = {
    tradeType: tradeType,
  }
  return tradeDetails;
}


/**
 * getAddressType function begins here
 * Returns the type of address (wallet, contract, tokenContract)
*/
async function getAddressType(address) {
  
  const code = await alchemy.core.getCode(address);
  
  if (code === '0x') {
    return 'wallet';
  } else {
    // It's a contract of some sort.
    // Now we need to see if it's a token contract.
    // We'll use ERC20 as an example of a token standard.
    // ERC20 contracts should implement a `totalSupply` function.
    // If the contract has this function, we'll assume it's an ERC20 token.
    // Note: this is a simplification and might not always be accurate.
    const provider = new ethers.providers.JsonRpcProvider(fetchURL);
    const contract = new ethers.Contract(address, ['function totalSupply() view returns (uint256)'], provider);
    try {
      await contract.totalSupply();
      return 'tokenContract';
    } catch (error) {
      return 'contract';
    }
  }
}
/**
 * getAddressType function ends here
*/

/**
 * extractDetailsFromInput function begins here
 * Returns the details of the input (details.transaction hash, details.address, details.addressType)
*/
async function extractDetailsFromInput(input) {
  const transactionRegex = /0x([A-Fa-f0-9]{64})/;
  const addressRegex = /0x([A-Fa-f0-9]{40})/;

  let transactionMatch = input.match(transactionRegex);
  let addressMatch = transactionMatch ? null : input.match(addressRegex);

  let details = {};

  if (transactionMatch) {
      details.transactionHash = transactionMatch[0];
  } else if (addressMatch) {
      details.addressType = await getAddressType(addressMatch[0]);
      details.address = addressMatch[0];
  }

  return details;
}
/**
 * extractDetailsFromInput function ends here
*/

/**
 * getAPIResponse function begins here
 * Returns formatted text for display in the chat
*/
function parseResponse(response) {
  const parts = response.split("\n");
  const imageUrlPattern = /(https?:\/\/[^"]+\.(?:jpg|jpeg|gif|png|svg))/i;

  const formattedResponse = parts.map(part => {
    if(imageUrlPattern.test(part)) {
      const imageUrl = part.match(imageUrlPattern)[0];
      return <img src={imageUrl} alt="AI Response" />;
    } else if (part.startsWith("text:")) {
        return <p>{part.slice(5)}</p>;  // Remove "text:" and use the rest as the inner text
    } else if (part.startsWith("bullet:")) {
        return <li>{part.slice(7)}</li>;  // Remove "bullet:" and use the rest as the inner text
    } else if (part.startsWith("number:")) {
        return <li>{part.slice(7)}</li>;  // Remove "number:" and use the rest as the inner text
    } else {
        return <p>{part}</p>;  // Just use the whole thing as the inner text
    }
  });
  return formattedResponse;
};
/**
 * getAPIResponse function ends here
*/

/**
 * getTotalSupply function begins here
 * Returns the total supply of a token in Ether format
*/
async function getTotalSupply(tokenAddress) {
  const provider = new ethers.providers.JsonRpcProvider(fetchURL);
  const contract = new ethers.Contract(tokenAddress, ['function totalSupply() view returns (uint256)'], provider);
  try {
    const totalSupply = await contract.totalSupply();
    // return in ether format not in wei
    return ethers.utils.formatEther(totalSupply);
  } catch (error) {
    //console.log(error);
    return null;
  }
}
/**
 * getTotalSupply function ends here
*/

// API Call: Get recent transactions of an address
async function getRecentTransactions(address) {
  const transactions = await EtherscanService.getTransactionList(address);
  //console.log(transactions)
  
  // slice the array to get no more than 10 transactions
  const limitedTransactions = transactions.slice(0, 10);

  const transactionHashes = limitedTransactions.map((transaction) => { return transaction.hash });
  return transactionHashes;
}


/**
 * End of Utility functions
*/


/** API Call methods */
// API Call: Get information about a wallet address
async function getWalletData(address) {
    const fromBalance = await alchemy.core.getBalance(address);
    const transactionCount = await alchemy.core.getTransactionCount(address);
    const tokens = await alchemy.core.getTokenBalances(address);
    const tokensDataPromises = tokens.tokenBalances.map(async (token) => {
      const metadata = await alchemy.core.getTokenMetadata(token.contractAddress);
      const balanceHex = ethers.BigNumber.from(token.tokenBalance);
      const balanceDec = ethers.utils.formatUnits(balanceHex, metadata.decimals);
      return { ...metadata, balance: balanceDec, contractAddress: token.contractAddress };
    });
    
    const recentTransactions = await getRecentTransactions(address);
  
    const tokensData = await Promise.all(tokensDataPromises);
    return {
      "address": address,
      "type": "wallet",
      "currentBalanceRaw": ethers.utils.formatEther(fromBalance),
      "currentBalance": `${ethers.utils.formatEther(fromBalance)} Ether`,
      "transactionCount": transactionCount,
      "numberOfTokens": tokensData.length,
      "tokensData": tokensData,
      "recentTransactions": recentTransactions
    }
}

// API Call: Get information about a contract address
async function getContractData(address) {
  return {
  }
}

// API Call: Get information about a token contract address
async function getTokenContractData(address) {
  const tokenContractInfo = await alchemy.core.getTokenMetadata(address);
  const tokenSupply = await getTotalSupply(address);
  return {
    "logo": tokenContractInfo.logo,
    "address": address,
    "type": "tokenContract",
    "name": tokenContractInfo.name,
    "symbol": tokenContractInfo.symbol,
    "decimals": tokenContractInfo.decimals,
    "totalSupply": tokenSupply,
    "type": "tokenContract"
  }
}

// API Call: Get transaction details for a transaction hash using eth_getTransactionByHash endpoint in Alchemy
async function getTransactionData(transactionHash) {

  // Make the request and return the response:
    const response = await alchemy.core.getTransaction(transactionHash);
    
    const fromAddressType = await getAddressType(response.from);
    const fromAddressInfo = 
      fromAddressType === 'wallet' ? await getWalletData(response.from) : 
      fromAddressType === 'contract' ? await getContractData(response.from) :
      fromAddressType === 'tokenContract' ? await getTokenContractData(response.from) : null;

    const toAddressType = await getAddressType(response.from);
    const toAddressInfo = 
      toAddressType === 'wallet' ? await getWalletData(response.to) : 
      toAddressType === 'contract' ? await getContractData(response.to) :
      toAddressType === 'tokenContract' ? await getTokenContractData(response.to) : null;      

    return {
      "response": response,
      "fromAddressInfo": fromAddressInfo,
      "toAddressInfo": toAddressInfo
    }
}

// Get transaction info from getTransactionInfo and then process it and then return it
async function getTransactionDetails(transactionHash) {
  const transactionInfo = await getTransactionData(transactionHash);
  const formattedResult = {
    "Block Hash": transactionInfo.response.blockHash,
    "Block Number": parseInt(transactionInfo.response.blockNumber, 10),
    "Transaction Hash": transactionInfo.response.hash,
    "Confirmations": transactionInfo.response.confirmations,
    "From": transactionInfo.response.from,
    "Gas Limit": `${ethers.utils.formatUnits(ethers.BigNumber.from(transactionInfo.response.gasLimit._hex), 'gwei')} Gwei`,
    "Gas Price": `${ethers.utils.formatUnits(ethers.BigNumber.from(transactionInfo.response.gasPrice._hex), 'gwei')} Gwei`,
    "Max Priority Fee Per Gas": `${ethers.utils.formatUnits(ethers.BigNumber.from(transactionInfo.response.maxPriorityFeePerGas._hex), 'gwei')} Gwei`,
    "Max Fee Per Gas": `${ethers.utils.formatUnits(ethers.BigNumber.from(transactionInfo.response.maxFeePerGas._hex), 'gwei')} Gwei`,
    "Nonce": transactionInfo.response.nonce,
    "To": transactionInfo.response.to,
    "Transaction Index": transactionInfo.response.transactionIndex,
    "Type": transactionInfo.response.type,
    "Value": `${ethers.utils.formatEther(ethers.BigNumber.from(transactionInfo.response.value._hex))} Ether`,
    "Data": transactionInfo.response.data,
    "FromInfo": transactionInfo.fromAddressInfo,
    "ToInfo": transactionInfo.toAddressInfo
  };
  // convert formattedResult to JSON string
  const formattedResultString = JSON.stringify(formattedResult, null, 2);
  //console.log(formattedResultString);
  return formattedResultString;
}

// Get wallet info from getWalletData and then process it and then return it
async function getWalletDetails(walletAddress) {
  const walletInfo = await getWalletData(walletAddress);
  //console.log(walletInfo)
  const formattedResult = {
    "Address": walletInfo.address,
    "Balance": walletInfo.currentBalance,
    "Transaction Count": walletInfo.transactionCount,
    "Type": walletInfo.type,
    "NumberOfTokens": walletInfo.tokens,
    "TokensData": walletInfo.tokensData,
    "RecentTransactions": walletInfo.recentTransactions
  };
  // convert formattedResult to JSON string
  const formattedResultString = JSON.stringify(formattedResult, null, 2);
  //console.log(formattedResultString);
  return formattedResultString;
}

// Get token contract info from getTokenContractData and then process it and then return it
async function getTokenContractDetails(tokenContractAddress) {
  const tokenContractInfo = await getTokenContractData(tokenContractAddress);
  const formattedResult = {
    "Address": tokenContractInfo.address,
    "Name": tokenContractInfo.name,
    "Symbol": tokenContractInfo.symbol,
    "Decimals": tokenContractInfo.decimals,
    "Total Supply": tokenContractInfo.totalSupply,
    "Type": tokenContractInfo.type,
    "Logo": tokenContractInfo.logo
  }
    const formattedResultString = JSON.stringify(formattedResult, null, 2);
    //console.log(formattedResultString);
    return formattedResultString;
};


// Receive user input and identity if it is a wallet address, token address, contract address or trasnaction hash
async function getAPIResponse(userInput) {
  const details = await extractDetailsFromInput(userInput);
  //console.log(details);

  // If it's a transaction hash, get the transaction details:
  const response = 
    details.transactionHash ? await getTransactionDetails(details.transactionHash) :
    details.addressType === 'tokenContract' ? await getTokenContractDetails(details.address) :
    details.addressType === 'wallet' ? await getWalletDetails(details.address) :
    null;
  
  return response;
}

// Function to get a particular token balance in a wallet
async function getTokenBalance(walletAddress, tokenContractAddress) {
  const tokenBalanceInHex = await alchemy.core.getTokenBalances(walletAddress, [tokenContractAddress]);
  //console.log(tokenBalanceInHex);
  const tokenBalance = ethers.utils.formatUnits(ethers.BigNumber.from(tokenBalanceInHex.tokenBalances[0]._hex), 'ether');
  return tokenBalance;
}

export const BlockexplorerService = {
  getAPIResponse,
  getAddressType,
  extractDetailsFromInput,
  parseResponse,
  getTradeDetails,
  getWalletData,
  getUniswapPair,
  getTokenBalance,
}
