import React, { useEffect, useState } from 'react'
import Header from './components/header'
import Chat from './components/chat'
import styles from './App.module.scss'
import { useTheme } from './hooks/useTheme';

const Layout = ({partnerBackground}) => {

    const { theme } = useTheme();

    return (
        <div className={theme === 'light' ?  styles.app : styles.app} style={{ ...partnerBackground }}>
            <Header />
            <Chat />
        </div> 
    )
    
}

export default Layout