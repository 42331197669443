import { useState, useEffect, createContext, useContext } from 'react';
import { OpenAIService } from '../services/openai.service';
import MessagesContext from '../constants/context';
import { handleMarketDataQueries } from './handlers/handleMarketDataQueries';
import { handleBlockExplorerQueries } from './handlers/handleBlockExplorerQueries';
import { handleWalletInfoQueries } from './handlers/handleWalletInfoQueries';
import { handleTradeQueries } from './handlers/handleTradeQueries';
import { handleNewsQueries } from './handlers/handleNewsQueries';
import { handleTwitterTrendQueries } from './handlers/handleTwitterTrendQueries';
import { handleWhitepaperQueries } from './partnerHandlers/handleWhitepaperQueries';
import { handleRoadmapQueries } from './partnerHandlers/handleRoadmapQueries';
import { handlePricemovementQueries } from './partnerHandlers/handlePricemovementQueries';
import { handleTeamQueries } from './partnerHandlers/handleTeamQueries';
import { handleTweetsQueries } from './partnerHandlers/handleTweetsQueries';
import { handleShillQueries } from './partnerHandlers/handleShillQueries';
import { BlockexplorerService } from "../services/blockexplorer.service";
import { useMetaMask } from './useMetaMask';
import { XGPT_API_URL } from '../constants/config';

// Create a context for the current provider, and export it.
const ChatContext = createContext({});

// Provider component that wraps your app and provides the chat state variables to your app and child components.
function ChatContextProvider({ children, partner }) {
    // State management
    const [messages, setMessages] = useState(MessagesContext);
    const [input, setInput] = useState('');
    const [showIntro, setShowIntro] = useState(true);
    const [fetching, setFetching] = useState(false);
    const { wallet, hasProvider, isConnecting, connectMetaMask } = useMetaMask();
    const [ connectedWalletInfo, setConnectedWalletInfo ] = useState(null);
    const [partnerInfo, setPartnerInfo] = useState(partner);

    // Useeffect to update partnerInfo when partner prop changes
    useEffect(() => {
        setPartnerInfo(partner);
    }, [partner]);

    // Scroll to bottom of chat window on new message
    useEffect(() => {
        const chatWindow = document.getElementById('chatWindow');
        if(chatWindow) {
            chatWindow.scrollTop = chatWindow.scrollHeight;
        }
        updateConnectedWalletInfo();
    }, [messages, wallet, hasProvider]);

    // Handling user input
    const handleInputChange = (e) => {
        setInput(e.target.value);
    };

    // Reset chat
    const resetChat = () => {
        setMessages(MessagesContext);
        setInput('');
        setShowIntro(true);
    }
    

    // Handle prompt input
    // Get prompt from the arguments and set it as the input and then submit the form
    const handlePromptInput = (prompt) => {
        handleFormSubmit({ preventDefault: () => {} }, prompt);
    }

    const handlePartnerPromptInput = (prompt) => {
        handlePartnerFormSubmit({ preventDefault: () => {} }, prompt);
    }

    // Handling user submit
    const handleFormSubmit = async (e, prompt) => {
        e.preventDefault();
        const newMessage = { role: 'user', content: prompt ? prompt : input};
        setMessages(prevMessages => [...prevMessages, newMessage]);
        setInput('');

        setFetching(true);

        // if showIntro is true, set to false. If false, leave as false.
        if (showIntro) {
            setShowIntro(false);
        }

        // create a clone of messages because manipulating state directly is bad and sometimes doesn't work
        const messagesClone = [...messages, newMessage];

        let availableFunctions = {
            'handleBlockExplorerQueries': handleBlockExplorerQueries,
            'handleMarketDataQueries': handleMarketDataQueries,
            'handleWalletInfoQueries': handleWalletInfoQueries,
            'handleTradeQueries': handleTradeQueries,
            'handleNewsQueries': handleNewsQueries,
            'handleTwitterTrendQueries': handleTwitterTrendQueries
        }


        // Call the OpenAIFunction API
        const filteredMessages = filterMessagesForOpenAI(messagesClone);
        //console.log(filteredMessages)
        
        const openAIResponseJSON = await fetch(XGPT_API_URL + 'openai/getAIFunctionResponse', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              mode: 'auto',
              messages: filteredMessages
            })
          });
        const openAIResponse = await openAIResponseJSON.json();
        console.log("openAIResponse", openAIResponse)

        // Get the response. If the response is a function call, execute the function. After execution, messagesClone will be updated with the response
        if(openAIResponse.tool_calls) {

            console.log("tool calls", openAIResponse.tool_calls[0].function.name)

            // get the function to call, get arguments, and call the function
            let functionName = openAIResponse.tool_calls[0].function.name;
            let functionToCall = availableFunctions[functionName];
            let functionArgs = JSON.parse(openAIResponse.tool_calls[0].function.arguments);

            let trade = {}

            let stateArgs = {
                messages: messages,
                setMessages: setMessages,
                messagesClone: messagesClone,
                connectedWalletInfo: connectedWalletInfo,
                wallet: wallet,
                trade: trade,
                fetching: fetching,
                setFetching: setFetching,
            }

            await functionToCall(functionArgs, stateArgs);
        } else {

            // If no function call, then just show the response from OpenAI
            messagesClone.push({role: 'assistant', content: openAIResponse.content});
            //const openAIResponseFormatted = BlockexplorerService.parseResponse(openAIResponse.content);
            setMessages(prevMessages => [...prevMessages, {role: 'assistant', content: openAIResponse.content, formattedContent: openAIResponse.content}]);
            setFetching(false);
        }
            
    };

    // Handling user submit
    const handlePartnerFormSubmit = async (e, prompt) => {

        //console.log('partner form submit')

        const partnerMessagesContext = [
            {
            'id': '1',
            'role': 'system',
            'content': `This is not XGPT's AI. This is the AI assistant for ${partnerInfo.name}. Do not mention about XGPT AI`
            },
            {
            'id': '2',
            'role': 'system',
            'content': "Do not ever convey any realtime data like price or  marketcap or any other real time data without invoking function call"
            },
            {
            'id': '3',
            'role': 'system',
            'content': "You do not have any trading capabilities. But very soon you will have them"
            },
            {
            'id': '5',
            'role': 'system',
            'content': "when asked about the assistant. Always tell about ${partnerInfo.name} AI in detail. Do not over emphasis on openai or XGPT related content. Focus on ${partnerInfo.name}."
            },
            {
            "id": "6",
            "role": "system",
            "content": `Do not reply to any question that is not related to ${partnerInfo.name}. If the user asks about any other topic, just say 'I am ${partnerInfo.name} AI. I am here to provide information about ${partnerInfo.name}. Please ask me anything about ${partnerInfo.name}.'`
            },
            {
            'id': '7',
            'role': 'system',
            'content': `Introduce yourself as ${partnerInfo.name} AI. Always start with that. If user says hello, reply with 'Hello! I am ${partnerInfo.name} AI. I am here to provide information about ${partnerInfo.name}. Please ask me anything about ${partnerInfo.name}.'"`
            },
        ]

        // If showIntro is true which means this is the first conversation. We need to remove the messages context from messages and replace it with partner's intro message
        if (showIntro) {
            setMessages([]);
            setMessages(prevMessages => [...prevMessages, ...partnerMessagesContext]);         
        }


        e.preventDefault();
        const newMessage = { role: 'user', content: prompt ? prompt : input};
        setMessages(prevMessages => [...prevMessages, newMessage]);
        setInput('');

        setFetching(true);

        // if showIntro is true, set to false. If false, leave as false.
        if (showIntro) {
            setShowIntro(false);
        }

        // create a clone of messages because manipulating state directly is bad and sometimes doesn't work
        const messagesClone = [...messages, newMessage];

        let availableFunctions = {
            'handleBlockExplorerQueries': handleBlockExplorerQueries,
            'handleMarketDataQueries': handleMarketDataQueries,
            'handleWalletInfoQueries': handleWalletInfoQueries,
            'handleTradeQueries': handleTradeQueries,
            'handleNewsQueries': handleNewsQueries,
            'handleTwitterTrendQueries': handleTwitterTrendQueries,
            'handleWhitepaperQueries': handleWhitepaperQueries,
            'handleRoadmapQueries': handleRoadmapQueries,
            'handlePricemovementQueries': handlePricemovementQueries,
            'handleTeamQueries': handleTeamQueries,
            'handleTweetsQueries': handleTweetsQueries,
            'handleShillQueries': handleShillQueries
        }


        // Call the OpenAIFunction API
        const filteredMessages = filterMessagesForOpenAI(messagesClone);
        //console.log(filteredMessages)
        
        const openAIResponseJSON = await fetch(XGPT_API_URL + 'partner/openai/getAIFunctionResponse', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              mode: 'auto',
              messages: filteredMessages
            })
          });
        const openAIResponse = await openAIResponseJSON.json();

        // Get the response. If the response is a function call, execute the function. After execution, messagesClone will be updated with the response
        if(openAIResponse.function_call) {

            // get the function to call, get arguments, and call the function
            let functionName = openAIResponse.function_call.name;
            let functionToCall = availableFunctions[functionName];
            let functionArgs = JSON.parse(openAIResponse.function_call.arguments);

            let trade = {}

            let stateArgs = {
                messages: messages,
                setMessages: setMessages,
                messagesClone: messagesClone,
                connectedWalletInfo: connectedWalletInfo,
                wallet: wallet,
                trade: trade,
                fetching: fetching,
                setFetching: setFetching,
                partnerInfo: partnerInfo
            }

            await functionToCall(functionArgs, stateArgs);
        } else {

            // If no function call, then just show the response from OpenAI
            messagesClone.push({role: 'assistant', content: openAIResponse.content});
            const openAIResponseFormatted = BlockexplorerService.parseResponse(openAIResponse.content);
            setMessages(prevMessages => [...prevMessages, {role: 'assistant', content: openAIResponse.content, formattedContent: openAIResponseFormatted}]);
            setFetching(false);
        }
            
    };

    const updateConnectedWalletInfo = async () => {
        const WalletInfo =
        // If there is a provider and accounts, return all accounts in the wallet
        hasProvider && wallet.accounts.length > 0 ? "Connected" :
        // If there is a provider, but no accounts, then return wallet not connected
        window.ethereum && window.ethereum.isMetaMask && wallet.accounts.length < 1 ? "NotConnected" :
        // If there is no provider, show install metamask button
        !hasProvider ? "NoProvider" : null;

        setConnectedWalletInfo(WalletInfo);
    }

    const filterMessagesForOpenAI = (rawMessages) => {
        // Format the messages array for OpenAI API
        return rawMessages.map(message => {
            const { formattedContent, id, ...rest } = message;
            return rest;
        });
    }

    return (
        <ChatContext.Provider 
            value={{ 
                messages, 
                input, 
                showIntro, 
                fetching,
                partnerInfo,
                handleInputChange, 
                handleFormSubmit,
                handlePartnerFormSubmit,
                handlePromptInput,
                handlePartnerPromptInput,
                resetChat,
            }}>
            {children}
        </ChatContext.Provider>
    )
};

// Custom hook to use the ChatContext
function useChat() {
    const context = useContext(ChatContext);
    if (context === undefined) {
        throw new Error('useChat must be used within a ChatContextProvider');
    }
    return context;
}

export { ChatContextProvider, useChat }
export default ChatContextProvider;
