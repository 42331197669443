import { XGPT_API_URL } from "../../constants/config";
import { v4 as uuidv4 } from 'uuid';

const parseJSONResponse = async (response) => {
    // Check if the response is okay and content type is JSON
    if (response.ok && response.headers.get("content-type")?.includes("application/json")) {
        // Parse the JSON response
        return await response.json();
    } else {
        // Handle non-JSON responses or responses that are not okay
        return null;
    }
};

const handlePricemovementQueries = async (argsFromAI, argsFromState) => {
    
    let messagesClone = argsFromState.messagesClone;
    let setMessages = argsFromState.setMessages;
    let setFetching = argsFromState.setFetching;
    let partnerInfo = argsFromState.partnerInfo;

    const apiResponseJSON = await fetch(XGPT_API_URL + 'marketdatav2/getMarketData?tokenName=' + partnerInfo.name);
    const apiResponse = await parseJSONResponse(apiResponseJSON);

    messagesClone.push(
            { role: 'system', content: JSON.stringify(apiResponse) },
            { role: 'system', content: "The above system message has all the market data. Present just the data related to price and price movements to the user"},
        );

    //console.log(apiResponse, filterMessagesForOpenAI(messagesClone))
    await converseWithOpenAI();
    async function converseWithOpenAI() {
        try {

            //console.log(filterMessagesForOpenAI(messagesClone))

            const responseJSON = await fetch(XGPT_API_URL + 'openai/getAIFunctionResponse', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    mode: 'none',
                    messages: filterMessagesForOpenAI(messagesClone)
                })
            });
            //console.log("check 1", responseJSON)
            const openAIResponse = await responseJSON.json();
            setMessages(prevMessages => [...prevMessages, {role: 'assistant', content: openAIResponse.content, formattedContent: openAIResponse.content}]);
            setFetching(false);
        } catch (error) {
            console.error('Failed to fetch from OpenAI:', error);
            setFetching(false);
        }
    }

    function filterMessagesForOpenAI (rawMessages) {
        // Format the messages array for OpenAI API
        return rawMessages.map(message => {
            const { formattedContent, loadingMessageItem, id, isTyping,  ...rest } = message;
            return rest;
        });
    }
};

export { handlePricemovementQueries };