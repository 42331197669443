import React, { useState, useEffect } from 'react';
import { Button, Container, Offcanvas } from 'react-bootstrap';
import Logo from './../assets/images/logo.svg'
import { CgMenuGridO, CgArrowLeft } from 'react-icons/cg'
import styles from './header.module.scss'
import { useMetaMask } from '../hooks/useMetaMask';
import { useChat } from '../hooks/useChat';
import { useTheme } from '../hooks/useTheme'
import Switch from "react-switch";

function Header() {

    // set state for metamask and sidemenu
    const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
    const { wallet, hasProvider, isConnecting, connectMetaMask } = useMetaMask();
    const { showIntro, resetChat, partnerInfo } = useChat();
    const { theme, handleTheme } = useTheme()

    const containerStyle = {
        '--primary-accent': partnerInfo && partnerInfo.theme ? partnerInfo.theme.dark.primaryAccent : "",
        '--secondary-accent': partnerInfo && partnerInfo.theme ? partnerInfo.theme.dark.secondaryAccent : "",
        '--primary-surface': partnerInfo && partnerInfo.theme ? partnerInfo.theme.dark.primarySurface : "",
        '--secondary-surface': partnerInfo && partnerInfo.theme ? partnerInfo.theme.dark.secondarySurface : "",
        '--tertiary-surface': partnerInfo && partnerInfo.theme ? partnerInfo.theme.dark.tertiarySurface : "",
        '--parimary-foreground': partnerInfo && partnerInfo.theme ? partnerInfo.theme.dark.parimaryForeground : "",
        '--secondary-foreground': partnerInfo && partnerInfo.theme ? partnerInfo.theme.dark.secondaryForeground : "",
      };


    // Handling menu button click
    const handleMenuButtonClick = () => {
        setIsSideMenuOpen(!isSideMenuOpen);
    };

    // UI element for wallet status
    const WalletStatus =
        // If there is a provider and accounts, show the first account
        hasProvider && wallet.accounts.length > 0 ? <a className={styles.item}  href={`https://etherscan.io/address/${wallet}`} target='_blank'>{wallet.accounts[0].slice(0, 6)}...{wallet.accounts[0].slice(-4)}</a> :
        // If there is a provider, but no accounts, show connect wallet button
        window.ethereum && window.ethereum.isMetaMask && wallet.accounts.length < 1 ? <Button className={styles.customButton} variant="primary" onClick={connectMetaMask} disabled={isConnecting}>Connect Wallet</Button> :
        // If there is no provider, show install metamask button
        !hasProvider ? <a className={styles.customButton} variant="primary">Install MetaMask</a> : '';

    const LogoComponent = 
        <a href={partnerInfo ? "/?partner=" + partnerInfo.name : "/"} className={styles.logo}>
            <img src={partnerInfo ? partnerInfo.logo[0].downloadURL : Logo} alt="Logo" className={styles.logoImg} />
        </a>
    

    // write a short function to format the website link for display purpose. Remove https:// or http:// or www. from the link
    const formatWebsiteLink = (link) => {
        return link.replace(/(^\w+:|^)\/\//, '').replace('www.', '');
    }

    const WebsiteLink =
        partnerInfo ? <a className={styles.item} href={partnerInfo.socialMedia.website} target="_blank" style={containerStyle}><CgArrowLeft /> {formatWebsiteLink(partnerInfo.socialMedia.website)}</a> :
        <a className={styles.item} href="https://www.xgpt-ai.com" target="_blank"><CgArrowLeft /> XGPT-AI.com</a>

    return (
        <div className={styles.header}>
            <Container className={styles.headerContainer}>
                <div className={styles.menuContainer}>
                    {WebsiteLink}
                    {/* <div className={styles.item} href="#">Modules</div>
                    <div className={styles.item} href="#">Prompts</div> */}
                </div>
                { !showIntro ? LogoComponent : '' }
                <div className={styles.actionContainer}>
                    {/* <div onClick={handleTheme}>Change Mode {theme}</div> */}
                    {/* <Switch 
                        className={styles.switch}
                        onChange={handleTheme} 
                        checked={theme === 'light'}  
                        onColor={'#14142D'} 
                        offColor={'#ffffff'} 
                        onHandleColor={'#ffffff'} 
                        offHandleColor={'#14142D'} 
                        checkedIcon={false} 
                        uncheckedIcon={false} /> */}
                    <a className={styles.item} onClick={() => resetChat()} href="#" style={containerStyle}>+ New Chat</a>
                    {!partnerInfo ? WalletStatus : ''} 
                </div>
                <Button className={`${styles.customButton} ${styles.toggleButton}`} variant="primary" onClick={handleMenuButtonClick}>
                    <CgMenuGridO />
                </Button>
            </Container>
            <Offcanvas className={styles.offcanvasMenu} show={isSideMenuOpen} onHide={handleMenuButtonClick} placement='bottom'>
                <Offcanvas.Body>
                    {WalletStatus}
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}

export default Header;