import { ethers } from 'ethers';
import { UniswapV2Router02 } from '@uniswap/v2-periphery/contracts/UniswapV2Router02.sol'; // This assumes you've installed the @uniswap/v2-periphery package
import IUniswapV2Router02 from '@uniswap/v2-periphery/build/UniswapV2Router02.json';
import { BlockexplorerService } from "../../services/blockexplorer.service";
import { MarketdataService } from "../../services/marketdata.service";
import { OpenAIService } from "../../services/openai.service";
import IERC20ABI from "../../constants/IERC20ABI.json";
import { XGPT_API_URL } from '../../constants/config';
import { v4 as uuidv4 } from 'uuid';

const IUniswapV2Router02ABI = IUniswapV2Router02.abi;
const errorResponses = {
    "NotFoundError": "I'm sorry, I could not find any matching coin. I have been trained on major listed tokens only. But I will be expanding my training to other platform like Uniswap and soon I can answer about these. Please try again.",
    "NotEthereumNetworkError": "The token is not on Ethereum network. Please try again with a token on Ethereum network.",
    "NotFoundOnUniswapError": "The token is not found on Uniswap. Please try again with a token that is listed on Uniswap.",
    "NotETHPairError": "The token is not paired with ETH on Uniswap. Please try again with a token that is paired with ETH on Uniswap.",
}

const handleTradeQueries = async (argsFromAI, argsFromState) => {

    const { tokenName, tradeType, tradeAmount, finalAuthorization } = argsFromAI;
    //console.log(tokenName, tradeType, tradeAmount, finalAuthorization);

    let isAuthorized = false;
    isAuthorized = finalAuthorization;

    let connectedWalletInfo = argsFromState.connectedWalletInfo;
    let trade = argsFromState.trade;
    let messagesClone = argsFromState.messagesClone;
    let wallet = argsFromState.wallet;
    let setMessages = argsFromState.setMessages;
    let setFetching = argsFromState.setFetching;
    const walletAddress = wallet.accounts[0];


    // Get the provider from the window object
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    let tradeValidated = false;
    let tradePrepared = false;
    let tradeExecuted = false;

    // Validate trade
    tradeValidated = await validateTradeQuery();
    await converseWithOpenAI();

    

    async function converseWithOpenAI () {
        //console.log(filterMessagesForOpenAI(messagesClone));
        try {
            const responseJSON = await fetch(XGPT_API_URL + 'openai/getAIFunctionResponse', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    mode: 'none',
                    messages: filterMessagesForOpenAI(messagesClone)
                })
            });
            //console.log("check 2", responseJSON)
            const aiResponse = await responseJSON.json();
        
            // Assuming the response has a 'content' field and you need to parse it
            setMessages(prevMessages => [...prevMessages, {role: 'assistant', content: aiResponse.content, formattedContent: aiResponse.content}]);
            setFetching(false);
        } catch (error) {
            console.error('Failed to fetch from OpenAI:', error);
            setFetching(false);
        }
    }

    function filterMessagesForOpenAI (rawMessages) {
        // Format the messages array for OpenAI API
        return rawMessages.map(message => {
            const { formattedContent, loadingMessageItem, id, isTyping,  ...rest } = message;
            return rest;
        });
    }
    
    // Function to validate the trade query
    async function validateTradeQuery() {
        
        trade.status = "inProgress";
    
        /**
         * Step 1: Check if user is connected to a wallet
         * If not, add system message to messagesClone and return
         * If yes, add wallet details to trade variable
         */
        if (connectedWalletInfo !== "Connected") {
            messagesClone.push(
                {
                    role: 'system',
                    content: "User is not connected to a wallet. Ask the user to connect to a wallet and try again.",
                }
            );
            //console.log("Step 1: Failed. No wallet connected");
            return false;
        } else {
            //console.log("Step 1: Success. Wallet connected");
        }
    
        /**
         * Step 2: Check if the user has provided a token name
         * If not: Add system message to messagesClone asking for token name and return
         * If yes: Add token name to trade variable
         */
        if (!tokenName) {
            messagesClone.push(
                {
                    role: 'system',
                    content: "User has not provided a token name. Please ask the user to provide a token name and try again.",
                }
            );
            //console.log("Step 2: Failed. No token name provided");
            return false;
        } else {
            //console.log("Step 2: Success. Token name provided");
        }
    
        /**
         * Step 3: Check if the token name provided by the user is valid.
         * If not: Add system message to messagesClone asking for a valid token name and return
         * If yes: Add token name, symbol and address to trade variable
        */
        const matchingCoinJSON = await fetch(XGPT_API_URL + 'marketdata/getTokenPairDetails?tokenName=' + tokenName);
        const matchingCoin = await matchingCoinJSON.json();
        //console.log(matchingCoin)
        if (!matchingCoin) {
            messagesClone.push(
                {
                    role: 'system',
                    content: "User has provided an invalid token name. Please ask the user to provide a valid token name and try again.",
                }
            );
            //console.log("Step 3: Failed. Invalid token name provided");
            return false;
        } else {
            //console.log("Step 3: Success. Valid token name provided");
            trade.tokenName = matchingCoin.baseToken.name;
            trade.tokenSymbol = matchingCoin.baseToken.symbol;
            trade.tokenAddress = matchingCoin.baseToken.address;
            trade.chain = matchingCoin.chainId;
            trade.exchange = matchingCoin.dexId;
        }

        /**
         * Step 4: Check if the token is on Ethereum network
         * If not: Add system message to messagesClone asking for a token on Ethereum network and return
         * If yes: Add token data to trade variable
         */
        const isEthereumNetwork = trade.chain == "ethereum" ? true : false;

        if (!isEthereumNetwork) {
            messagesClone.push(
                {
                    role: 'system',
                    content: "The token is not on Ethereum network. Please ask the user to provide a token on Ethereum network and try again.",
                }
            );
            //console.log("Step 4: Failed. Token not on Ethereum network");
            return false;
        } else {
            trade.tokenDataJSON = await fetch(XGPT_API_URL + 'marketdata/getCoinData?tokenAddress=' + trade.tokenAddress);
            trade.tokenData = await trade.tokenDataJSON.json();
            //console.log(trade.tokenData)
            //console.log("Step 4: Success. Token on Ethereum network");
        }

        /**
         * Step 5: Check if the token is listed on Uniswap with a ETH pair
         * If not: Add system message to messagesClone asking for a token listed on Uniswap and return
         * If yes: Add uniswapPair data to trade variable
         */
        const uniswapPairJSON = await fetch(XGPT_API_URL + 'blockexplorer/getUniswapPair?tokenAddress=' + trade.tokenAddress);
        const uniswapPair = await uniswapPairJSON.json();
        if (!uniswapPair || !uniswapPair.liquidityToken) {
            messagesClone.push(
                {
                    role: 'system',
                    content: "The token is not listed on Uniswap. Please ask the user to provide a token listed on Uniswap and try again.",
                }
            );
            //console.log("Step 5: Failed. Token not listed on Uniswap");
            return false;
        } else {
            trade.uniswapPairData = uniswapPair;
            //console.log("Step 5: Success. Token listed on Uniswap");
        }

        /**
         * Step 6: If user has provided trade type
         * If not: Add system message to messagesClone asking for trade type and return
         * If yes: Add trade type to trade variable
         */
        if (!tradeType) {
            messagesClone.push(
                {
                    role: 'system',
                    content: "User has not provided a trade type. Please ask the user to provide a trade type and try again.",
                }
            );
            //console.log("Step 6: Failed. No trade type provided");
            return false;
        } else {
            trade.tradeType = tradeType;
            //console.log("Step 6: Success. Trade type provided");
        }

        /**
         * Step 7: If user has provided trade quantity. If tradeType is buy, trade quantity is in ETH. If tradeType is sell, trade quantity is in token
         * If not: Add system message to messagesClone asking for trade quantity and return
         * If yes: Add trade quantity to trade variable
         */
        if (!tradeAmount) {
            messagesClone.push(
                {
                    role: 'system',
                    content: "User has not provided a trade amount. Please ask the user to provide a trade amount strictly in ETH and try again.",
                }
            );
            //console.log("Step 7: Failed. No trade amount provided");
            return false;
        }
        else if (tradeAmount <= 0) {
            messagesClone.push(
                {
                    role: 'system',
                    content: "User has provided a trade amount less than or equal to zero. Please ask the user to provide a trade amount greater than zero and try again.",
                }
            );
            //console.log("Step 7: Failed. Trade amount less than or equal to zero");
            return false;
        }
        else {
            trade.tradeAmount = tradeAmount;
            //console.log("Step 7: Success. Trade quantity provided");
        }

        /**
         * Step 8: Check if user's wallet has enough balance to make this trade. If tradeType is buy check for ETH balance, if tradeType is sell check for token balance.
         * If not: Add system message to messagesClone and return
         * If yes: Prepare system message for final authorization.
         */

        if(trade.tradeType === 'buy') {
            const userWalletDataJSON = await fetch(XGPT_API_URL + 'blockexplorer/getWalletData?address=' + walletAddress);
            const userWalletData = await userWalletDataJSON.json();
            const userWalletBalanceInETH = userWalletData.currentBalanceRaw;
            //console.log(userWalletBalanceInETH, tradeAmount)
            if (userWalletBalanceInETH < tradeAmount) {
                messagesClone.push(
                    {
                        role: 'system',
                        content: "User does not have enough ETH in wallet. Please ask the user to provide more ETH and try again.",
                    }
                );
                //console.log("Step 8: Failed. User does not have enough ETH in wallet");
                return false;
            }
            else {
                trade.walletBalanceInETH = userWalletBalanceInETH;
                //console.log("Step 8: Success. User has enough ETH in wallet");
                await prepareTrade();
            }
        } else if(trade.tradeType === 'sell') {
            const walletDataJSON = await fetch(XGPT_API_URL + 'blockexplorer/getWalletData?address=' + walletAddress);
            const walletData = await walletDataJSON.json();
            //console.log(walletData, trade.tokenAddress)
            // walletData.tokensData is an array with property contractAddress. Filter it to get the token we are looking for and its balance
            const userWalletBalanceInToken = walletData.tokensData.filter(token => token.contractAddress.toLowerCase() === trade.tokenAddress.toLowerCase())[0].balance;
            if (userWalletBalanceInToken < tradeAmount) {
                messagesClone.push(
                    {
                        role: 'system',
                        content: "User does not have enough tokens in wallet. Please ask the user to provide more tokens and try again.",
                    }
                );
                //console.log("Step 8: Failed. User does not have enough tokens in wallet");
                return false;
            }
            else {
                trade.walletBalanceInToken = userWalletBalanceInToken;
                //console.log("Step 8: Success. User has enough tokens in wallet");
                await prepareTrade();
            }
        }

        /**
         * Step 9: Check for final authorization
         * If not: Add system message to messagesClone asking for final authorization and return
         * If yes: Execute the trade
         */
        if (!isAuthorized) {
            //console.log("Step 9: Failed. User has not authorized the trade");
            return false;
        }
        else {
            //console.log("Step 9: Success. User has authorized the trade");
            await executeTrade();
        }
    };
    
    // Function to prepare the trade
    async function prepareTrade () {
        
    
        // UniswapV2Router02 address. Replace this with the address of the router you want to use.
        const uniswapV2Router02Address = '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D';
        
        // Create a new contract instances
        const uniswapV2Router02 = new ethers.Contract(uniswapV2Router02Address, IUniswapV2Router02ABI, signer);
    
        const tokenAddress =  trade.tokenAddress;
        const wethAddress = await uniswapV2Router02.WETH();
    
    
        let path = [wethAddress, tokenAddress];
        if(tradeType === 'sell') {
            path = [tokenAddress, wethAddress];
        }

        // Get token decimals
        //console.log(trade)
        const tokenDecimals = trade.tokenData.decimals;
    
        // Calculate expected token output and minimum token output
        const amountIn = tradeType == 'buy' ? ethers.utils.parseEther(trade.tradeAmount.toString()) : ethers.utils.parseEther(trade.tradeAmount.toString(), tokenDecimals);
        // //console.log amountIn in decimal format
        //console.log(ethers.utils.formatEther(amountIn))
        const amountsOut = await uniswapV2Router02.getAmountsOut(amountIn, path);
        // //console.log amountsOut in decimal format
        //console.log(ethers.utils.formatEther(amountsOut[1]))
    
        const expectedTokenOutput = ethers.utils.formatEther(amountsOut[1]);
        //console.log(expectedTokenOutput)
    
        //Assuming 0.5% slippage tolerance for minimum token output
        const minimumTokenOutput = expectedTokenOutput * 0.995;
    
        // Calculate token price in ETH and USD
        const tokenPriceInETH = tradeType == 'buy' ? trade.tradeAmount / expectedTokenOutput : expectedTokenOutput / trade.tradeAmount;
        //console.log(tokenPriceInETH)
        const tokenPriceInUSD = trade.tokenData.price;
        // Get WETH price in USD
        const ethInfoJSON = await fetch(XGPT_API_URL + 'marketdata/getCoinData?tokenAddress=' + wethAddress);
        const ethInfo = await ethInfoJSON.json();
        const ethPriceInUSD = ethInfo.priceUsd;
    
        // Calculate quantity in USD
        const tradeAmountInUSD = tradeType == 'buy' ? tradeAmount * ethPriceInUSD : tradeAmount * tokenPriceInUSD;
    
        let tradeDetails = {
            "tradeType": tradeType,
            "tradeAmount": tradeAmount,
            "tradeAmountInUSD": tradeAmountInUSD,
            "expectedTokenOutput": expectedTokenOutput,
            "minimumTokenOutput": minimumTokenOutput,
            "tokenPriceInETH": tokenPriceInETH,
            "tokenPriceInUSD": tokenPriceInUSD,
            "slippageTolerance": 1.5,
        }
        trade.tradeDetails = tradeDetails;

        //Push to tradeDetails to messages clone and ask user to confirm trade
        messagesClone.push(
            {
                role: 'system',
                content: "Trade details prepared. Please show the user all trade details ask the user to confirm the trade." + JSON.stringify(tradeDetails),
            }
        );
        //console.log("Step 10: Success. Trade details prepared");
    
        return true;
    }
    

    // Function to execute the trade on Uniswap.
    async function executeTrade () {

        const amount = trade.tradeDetails.minimumTokenOutput;
        const tokenContractAddress = trade.tokenAddress;
        
        // UniswapV2Router02 address. Replace this with the address of the router you want to use.
        const uniswapV2Router02Address = '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D';
    
        // Create a new contract instance of the UniswapV2Router02 contract
        const uniswapV2Router02 = new ethers.Contract(uniswapV2Router02Address, IUniswapV2Router02ABI, signer);

        // Parse the amount to be swapped to the correct format
        const amountParsed = ethers.utils.parseUnits(amount.toString(), 18);
    
        // Set the slippage tolerance to 5%
        const slippageTolerance = ethers.BigNumber.from(10000 + 500); // 10000 represents the base (100%)
        const valueWithSlippage = amountParsed.mul(slippageTolerance).div(15000); // adjust for slippage

        //console.log(valueWithSlippage.toString())
    
        // Get current time to create valid deadline for the transaction
        const deadline = Math.floor(Date.now() / 1000) + 60 * 20; // 20 minutes from the current Unix time

        // I'm assuming you want to swap Ether for the provided token, hence WETH is the input and your token is the output.
        const path = [uniswapV2Router02.WETH(), tokenContractAddress]; 
    
        if (tradeType === 'sell') {
            path.reverse();
        }
    
        let tx;
        try {
            if (tradeType === 'buy') {
                // Execute the swap (ETH for Token)
                tx = await uniswapV2Router02.swapExactETHForTokens(
                    valueWithSlippage, // The minimum amount of output tokens that must be received for the transaction not to revert
                    path, // The path of the swap, represented by an array of token addresses
                    wallet.accounts[0], // The recipient's address
                    deadline, // The time by which the swap must complete
                    {
                        value: ethers.utils.parseEther(trade.tradeAmount.toString()), // The amount of Ether you want to swap
                        gasLimit: 250000, // You may need to adjust this gas limit
                    }
                );
                await addAssistantMessage("⏳ Executing trade. Please wait");
            } else {
                // Approve the Uniswap router to move your tokens
                // After approving add a assistant message to the messages array using setMessages that token spending is approved and actual trasnaction will begin now. Please wait
                const token = new ethers.Contract(tokenContractAddress, IERC20ABI, signer);
                const amountToSell = ethers.utils.parseUnits(trade.tradeAmount.toString(), 18);
                tx = await token.approve(uniswapV2Router02Address, amountToSell);


                await addAssistantMessage("⏳ Approving token spending. Please wait");

                await tx.wait();

                await addAssistantMessage("✅ Token spending approved. Transaction will begin now. Please wait");

    
                // Execute the swap (Token for ETH)
                tx = await uniswapV2Router02.swapExactTokensForETH(
                    amountToSell,
                    valueWithSlippage, // The minimum amount of ETH that must be received for the transaction not to revert
                    path, // The path of the swap, represented by an array of token addresses
                    wallet.accounts[0], // The recipient's address
                    deadline, // The time by which the swap must complete
                    {
                        gasLimit: 250000, // You may need to adjust this gas limit
                    }
                );
                await addAssistantMessage("⏳ Executing trade. Please wait");
            }
        } catch (error) {
            //console.log(error);
            messagesClone.push(
                {
                    role: 'system',
                    content: "Trade failed. Ask user to Please try again." + error,
                }
            );

            return false;
        }
    
        // Wait for the transaction to be mined
        const receipt = await tx.wait();
        
        // Add system message that trade was successful with transaction hash and amount of coins swapped
        messagesClone.push(
            {
                role: 'system',
                content: "Trade successful. Transaction hash: " + receipt.transactionHash + ". Amount of coins swapped: " + trade.tradeAmount,
            }
        );
        
        return true;
    }

    async function addAssistantMessage (message) {
        const aiMessage = message;
        const aiMessageFormatted = BlockexplorerService.parseResponse(aiMessage);
        setMessages(prevMessages => [...prevMessages, {role: 'assistant', content: aiMessage, formattedContent: aiMessageFormatted}]);
    }
};

export { handleTradeQueries };