import React, { useEffect, useState } from 'react'
import { MetaMaskContextProvider } from './hooks/useMetaMask'
import { ChatContextProvider } from './hooks/useChat'
import { ThemeContextProvider } from './hooks/useTheme'
import Layout from './Layout'
import { db } from './firebaseconfig'
import { collection, query, where, getDocs } from "firebase/firestore";

function App() {

  const urlParams = new URLSearchParams(window.location.search);
  const partner = urlParams.get('partner');

  // If there is a partner in the URL, we will get partner info from firestore database and assign it to partnerInfo.
  // If there is no partner in the URL or the partner is not found in the database, we will assign null to partnerInfo.

  // State to store partner info fetched
  const [partnerInfo, setPartnerInfo] = useState(null);

  // Fetch partner info from firestore
  useEffect(() => {
    if (partner) {
      const fetchPartnerInfo = async () => {
        // Get the document from the collection called "partners" where the document has the property name equal to the partner in the URL.
        const q = query(
          collection(db, 'partners'),
          where('nameLower', '==', partner.toLowerCase())
        );

        const querySnapshot = await getDocs(q);
        
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          //console.log(doc.data());
          setPartnerInfo(doc.data());
        });
      }
      fetchPartnerInfo();
    }
  }, [partner]);


  const partnerBackground = partnerInfo && partnerInfo.theme ? { background: partnerInfo.theme.dark.primarySurface } : {};


  return (
      <MetaMaskContextProvider>
        <ChatContextProvider partner={partnerInfo}>
          <ThemeContextProvider>
            <Layout partnerBackground = {partnerBackground} />
            {/* <div className={styles.app} style={{ ...partnerBackground }}>
              <Header />
              <Chat />
            </div>  */}
          </ThemeContextProvider>
        </ChatContextProvider>
      </MetaMaskContextProvider>
  );
}

export default App;
