import { XGPT_API_URL } from "../../constants/config";
import { v4 as uuidv4 } from 'uuid';

const handleTwitterTrendQueries = async (argsFromAI, argsFromState) => {
    
    let messagesClone = argsFromState.messagesClone;
    let setMessages = argsFromState.setMessages;
    let setFetching = argsFromState.setFetching;

    const trendsJSON = await fetch(XGPT_API_URL + 'trend/getCurrentTrend');
    const trends = await trendsJSON.json();
    
    // Create a string that can be fed to the ai which will be the list of coin names with its symbol in the bracket
    // trends is an array. Loop through the array and create a string. 
    const coinNamesString = trends.map(trend => {
        // ignore and omit if trend.data is null or undefined
        if (!trend.data) {
            return;
        }
        // else return the name of the coin and its symbol in the bracket
        return trend.name + " (" + trend.data.name + ")";
    })
    const apiResponse = "The top trending coins are based on XGPT AI's data analysis." + coinNamesString.join(", ") + ". ";

    messagesClone.push(
            { role: 'system', content: apiResponse },
            { role: 'system', content: "The above system message has the top trending coins based on XGPT AI's data analysis. Present this to the user"},
            { role: 'system', content: "Just show the tickers. Do not attempt to guess the name of the token from the ticker"},
            { role: 'system', content: "Do not show more than 15 tokens"},
            { role: 'system', content: "This is strictly not a financial advice."},
        );

    //console.log(apiResponse, filterMessagesForOpenAI(messagesClone))
    await converseWithOpenAI();
    async function converseWithOpenAI() {
        try {

            //console.log(filterMessagesForOpenAI(messagesClone))

            const responseJSON = await fetch(XGPT_API_URL + 'openai/getAIFunctionResponse', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    mode: 'none',
                    messages: filterMessagesForOpenAI(messagesClone)
                })
            });
            const openAIResponse = await responseJSON.json();
            console.log("check 2", openAIResponse);
            setMessages(prevMessages => [...prevMessages, {role: 'assistant', content: openAIResponse.content, formattedContent: openAIResponse.content}]);
            setFetching(false);
        } catch (error) {
            console.error('Failed to fetch from OpenAI:', error);
            setFetching(false);
        }
    }

    function filterMessagesForOpenAI (rawMessages) {
        // Format the messages array for OpenAI API
        return rawMessages.map(message => {
            const { formattedContent, loadingMessageItem, id, isTyping,  ...rest } = message;
            return rest;
        });
    }
};

export { handleTwitterTrendQueries };