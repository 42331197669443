const etherscanBaseURL = 'https://api.etherscan.io/api';

async function getTransactionList (address) {
    const url = `${etherscanBaseURL}?module=account&action=txlist&address=${address}&startblock=0&endblock=99999999&page=1&offset=10&sort=asc&apikey=${process.env.REACT_APP_ETHERSCAN_API_KEY}`;
    const response = await fetch(url);
    const data = await response.json();
    return data.result;
}

export const EtherscanService = {
    getTransactionList
};