import { Configuration, OpenAIApi } from 'openai';
import { BlockexplorerService } from "./blockexplorer.service";

// OpenAI API configuration
const configuration = new Configuration({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY
});

// OpenAI API instance
const openai = new OpenAIApi(configuration);

let functions = [
    {
        name: "handleBlockExplorerQueries",
        description: "Function to get block explorer data from the blockchain for a transaction hash, wallet address, token contract address or contract address. If it is too vague ask the user exactly for what they want to know about it",
        parameters: {
            type: "object",
            properties: {
                hexadecimalValue: {
                    type: "string",
                    description: "Transaction hash or wallet address or contract address from the user's input"
                },
                requestedInfo: {
                    type: "string",
                    description: "The information to get from the blockchain. For example: 'balance', 'transaction count', 'token balance', 'token transaction count', 'token name', 'token symbol', 'token total supply', 'token decimals', 'token holders count', 'token transfers count', 'token transfers'"
                }
            },
            required: ["hexadcimalValue", "requestedInfo"]                
        }
    },
    {
        name: "handleMarketDataQueries",
        description: "Function to get market data for a token. Sometimes user might just ask a very general enquiry too like `tell me about x`. When asked do not reply with anything more than the user has asked",
        parameters: {
            type: "object",
            properties: {
                tokenName: {
                    type: "string",
                    description: "Name of the token to get market data for. Sometimes if the token name is not mentioned it will be in the previous conversation."
                },
                requestedInfo: {
                    type: "string",
                    description: "The market data info to get about a token name like price, volume, 24high, 24low, total supply, market cap, etc."
                }
            },
            required: ["tokenName", "requestedInfo"]
        }
    },
    {
        name: "handleWalletInfoQueries",
        description: "When user asks anything about their connected wallet. They refer this to as my wallet or connected wallet or wallet or account in general without any specific address",
        parameters: {
            type: "object",
            properties: {
                requestedInfo: {
                    type: "string",
                    description: "Identify what the user is asking for. For example: 'wallet address', 'wallet balance', 'wallet transaction count', 'recent transactions', 'tokens stored in the wallet', 'token balance', 'token transaction count', 'token name', 'token symbol', 'token total supply', 'token decimals', 'token holders count', 'token transfers count', 'token transfers'"
                }
            }
        }       
    },
    {
        name: "handleTradeQueries",
        description: "Function to handle trade-related queries, such as buy and sell orders. Users can able to buy or sell tokens using the chatbot. Whenever user prompts to buy or sell tokens or coins invoke this function.",
        parameters: {
            type: "object",
            properties: {
                tokenName: {
                    type: "string",
                    description: "Name of the token to trade. User could be buying some tokens or selling. Get the token name from the user's input. User might say like `I want to buy ETH tokens`"
                },
                tradeType: {
                    type: "string",
                    description: "Type of trade action to be taken (e.g., 'buy' or 'sell'). If swapping tokens for eth then the trade type will be 'sell' and if swapping eth for tokens then the trade type will be 'buy'"
                },
                tradeAmount: {
                    type: "number",
                    description: "Quantity of tokens to be traded. If tradeType is buy Ask the user to provide the quantity in ETH. If tradeType is sell Ask the user to provide the quantity in tokens. If no tradeType is detected leave this undefined."
                },
                finalAuthorization: {
                    type: "boolean",
                    description: "This will be true if the user has confirmed the trade after providing the trade details by the Assistant. Strictly do not assume this value to be true. Only when the assistant asks for confirmation with trade details and the user replies affirmative only then this value will be true."
                }
            }, required: ["tokenName", "tradeType", "tradeAmount"]
        }
    }
]

// Function to send input to OpenAI API with context and get response
async function getAIResponse (messagesForOpenAI) {

    //console.log(messagesForOpenAI);
    // Send input to OpenAI API
    try {
        const response = await openai.createChatCompletion({
            model: "gpt-3.5-turbo-16k",
            messages: messagesForOpenAI
        });
        
        // return the response data
        return response.data.choices[0].message;
    } catch (error) {
        if (error.response) {
            //console.log(error.response.status);
            //console.log(error.response.data);
        } else {
            //console.log(error.message);
        }
        // Return null or an error object in case of an error
        return null;
    }
};

async function getAIFunctionResponse (mode, messagesForOpenAI, retryCount = 0) {
    // Send input to OpenAI API

    // messagesForOpenAI.push(
    //     {role: 'system', content: "Don't make assumptions about what values to plug into functions. Ask for clarification if a user request is ambiguous."}
    // );

    try {
        const response = await openai.createChatCompletion({
            model: "gpt-3.5-turbo-16k",
            messages: messagesForOpenAI,
            functions: functions,
            function_call: mode
        });

        // Process the response to extract the function name and the arguement
        return response.data.choices[0].message;
    } catch (error) {
        if (error.response && error.response.status === 503 && retryCount < 5) {
            // Wait for an exponential amount of time (2^retryCount seconds, plus a random fraction of a second)
            const waitTime = (2 ** retryCount + Math.random()) * 1000;
            //console.log(`Got 503 error, waiting for ${waitTime} ms before retrying...`);
            await new Promise(resolve => setTimeout(resolve, waitTime));

            // Retry the request
            return getAIFunctionResponse(mode, messagesForOpenAI, retryCount + 1);
        } else {
            //console.log(error.response ? (error.response.status + ' ' + error.response.data) : error.message);
            // Return null or an error object in case of an error
            return null;
        }
        // Return null or an error object in case of an error
        return null;
    }
}

// Function to check if the user has mentioned a token name in the input
async function mentionedTokenName (input) {
    const decisionContext = [
        {
            "role": "system",
            "content": "You are an assistant that identifies and states the name of the cryptocurrency token mentioned in user's sentences. If there is no token name mentioned, just say 'Not Mentioned'. If there is a token mentioned just say 'Mentioned {token name}' Do not attempt to fetch real-time data. Assistant might not recognize the token name since the token might be new. But still just tell the token name. Do not reply anything else or than instructed. Strictly do not respond anything other than 'Mentioned {Token name}' or 'Not Mentioned'",
        },
        {
            "role": "user",
            "content": "What is the price of Bitcoin?"            
        },
        {
            "role": "assistant",
            "content": "Mentioned Bitcoin"
        },
        {
            "role": "user",
            "content": "What is the all time high of Ethereum?"
        },
        {
            "role": "assistant",
            "content": "Mentioned Ethereum"
        },
        {
            "role": "user",
            "content": "Total volume in last 24 hours?"
        },
        {
            "role": "assistant",
            "content": "Not Mentioned"
        },
        {
            "role": "user",
            "content": "Will it rain today?"
        },
        {
            "role": "assistant",
            "content": "Not Mentioned"
        }
    ]
        
    const decision = await getAIResponse(decisionContext, input);
    //console.log(decision)
    return decision;
}

// Function to classify the intent of the user's input into what the user is trying to achieve. It could be "Trade, MarketData, BlockExplorer or General"
async function classifyIntent (input) {

    // Create context with instructions for the AI assistant and example inputs for each category
    const classifyIntentContext = [
        {
            role: 'system',
            content: "There could be a MetaMask wallet connected by the user. Very precisely identity user's intent and classify into 'Trade', 'MarketData', 'BlockExplorer', 'WalletInfo' or 'General'. Do not reply anything else other these keywords"
        },
        {
            role: 'user',
            content: 'What is the price of Bitcoin?'
        },
        {
            role: 'assistant',
            content: 'MarketData'
        },
        {
            role: 'user',
            content: 'Tell me the about 0x0f7f961648ae6db43c75663ac7e5414eb79b5704f4'
        },
        {
            role: 'assistant',
            content: 'BlockExplorer'
        },
        {
            role: 'user',
            content: 'Buy 1000 Bitcoin'
        },
        {
            role: 'assistant',
            content: 'Trade'
        },
        {
            role: 'user',
            content: 'How are you doing?'
        },
        {
            role: 'assistant',
            content: 'General'
        },
        {
            role: 'user',
            content: 'What is my account balance?'
        },
        {
            role: 'assistant',
            content: 'WalletInfo'
        },
        {
            role: 'user',
            content: 'My next prompt will be to classify. Remember I dont want you to reply anything else other than the keywords I mentioned'
        },
        {
            role: 'assistant',
            content: 'OK. I will not respond with anything else other than the keywords you mentioned. You cannot ask any questions for clarification.'
        },
    ]

    // Send input to OpenAI API
    const intent = await getAIResponse(classifyIntentContext, input);
    //console.log(intent)
    return intent;
};

// Function to determine whether a new topic is starting or not
async function isNewTopic(messagesForOpenAI, input) {
    // Get the token name mentioned in the new user input
    const newTokenName = await mentionedTokenName(input);
    
    // If no token is mentioned in the new user input, it's not a new topic
    if (newTokenName === 'Not Mentioned') {
        return false;
    }
    
    // Get the token name mentioned in the last user message
    const lastUserMessage = messagesForOpenAI.reverse().find(message => message.role === 'user');
    const lastTokenName = await mentionedTokenName(lastUserMessage.content);
    
    // If the token mentioned in the new user input is different from the token mentioned in the last user message, it's a new topic
    if (newTokenName !== lastTokenName) {
        return true;
    }
    
    // If the token mentioned in the new user input is the same as the token mentioned in the last user message, it's not a new topic
    return false;
}

// Function to determine wheterh the user input is affirmative or negative
async function isAffirmative(input) {
    
    // Create context with instructions for the AI assistant and example inputs
    const isAffirmativeContext = [
        {
            role: 'system',
            content: "Stricly very precisely just say if the user's input is affirmative or negative. Do not reply anything else other than 'Affirmative' or 'Negative'"
        }
    ]

    // Send input to OpenAI API
    const decision = await getAIResponse(isAffirmativeContext, input);
    //console.log(decision)
    return decision;
}


export const OpenAIService = { getAIResponse, getAIFunctionResponse, mentionedTokenName, classifyIntent, isNewTopic, isAffirmative };
